import React, { useContext, useEffect, useState } from 'react'
import { Web3Context } from '../contexts/Web3Context'
import Catch from '../components/Catch'
import { fromWei, toWei } from 'web3-utils'
import cn from 'classnames'
import styles from '../components/_Operations/Buy/Buy.module.scss'
import config from '../config'
import { useStateIfMounted } from 'use-state-if-mounted'

export const useApprove = (contracts) => {
  const { defaultAccount, isConnected, isCorrectNetwork, web3, getErc20Contract } = useContext(Web3Context)

  const [isApproved, setIsApproved] = useStateIfMounted(false)
  const [isApprovingProcess, setIsApprovingProcess] = useStateIfMounted(true)
  const [missingApprove, setMissingApprove] = useState({})
  const [processingText, setProcessingText] = useState('Processing')

  const checkApprove = async () => {
    if (defaultAccount && isConnected && contracts.length > 0) {
      let allApproved = true

      for (let contract of contracts) {
        let source = contract.from
        if (contract.isErc20) {
          source = getErc20Contract(contract.from)
        }
        if (!contract?.to.options?.address || !source?.methods) {
          allApproved = false
        } else {
          try {
            if (contract.amount === true) {
              const approved = await source.methods.isApprovedForAll(defaultAccount, contract.to.options.address).call({ from: defaultAccount })
              if (!approved) {
                allApproved = false
                missingApprove[source.options.address] = contract
                setMissingApprove(missingApprove)
              }
            }
            if (contract.amount !== true && contract.amount > 0) {
              const approvedAmount = await source.methods.allowance(defaultAccount, contract.to.options.address).call({ from: defaultAccount })
              const isEnough = parseFloat(fromWei(approvedAmount, 'ether')) >= parseFloat(contract.amount)
              if (!isEnough) {
                contract.amount = Math.ceil(contract.amount - parseFloat(fromWei(approvedAmount)))
                allApproved = false
                missingApprove[source.options.address] = contract
                setMissingApprove(missingApprove)
              }
            }
          } catch ({ message }) {
            setIsApprovingProcess(false)
          }
        }
      }
      setIsApproved(allApproved)
      setIsApprovingProcess(false)
      return allApproved
    }
    return false
  }

// eslint-disable-next-line
  useEffect(() => checkApprove(), [isConnected, defaultAccount])

  /* eslint-disable no-loop-func */
  const makeApprove = async (event = false) => {
    if (event) {
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
    }
    if (isApproved) return
    setIsApprovingProcess(true)
    let countApproved = 0
    const missingLength = Object.values(missingApprove).length
    let i = 0
    for (let contract of Object.values(missingApprove)) {
      i++
      try {
        if (defaultAccount && isConnected && isCorrectNetwork && contract.to?.options?.address) {
          let source = contract.from
          if (contract.isErc20) {
            source = getErc20Contract(contract.from)
          }
          if (contract.amount === true) {
            setProcessingText(missingLength > 1 ? `${i}/${missingLength} Approving NFT` : 'Approving NFT')
            const calculateGP = await web3.eth.getGasPrice()
            await source.methods.setApprovalForAll(contract.to.options.address, true).send({ from: defaultAccount, gasPrice: calculateGP }).once('receipt', () => {
              setIsApproved(countApproved + 1 === missingLength)
              setIsApprovingProcess(countApproved + 1 !== missingLength)
              countApproved++
            }).on('error', error => {
              setIsApprovingProcess(false)
              countApproved++
              return Catch('Can`t approve', error.message)
            })
          }
          if (contract.amount !== true && contract.amount > 0) {
            const approveAmount = parseFloat(config.defaultAllowanceAmount) > parseFloat(contract.amount) ? config.defaultAllowanceAmount : contract.amount
            const symbol = Object.values(config.tokens).filter(t => t.options.address === source.options.address)?.[0].options.symbol ?? config.defaultToken
            setProcessingText(missingLength > 1 ? `${i}/${missingLength} Approving ${symbol}` : `Approving ${symbol}`)
            const calculateGP = await web3.eth.getGasPrice()
            await source.methods.increaseAllowance(contract.to.options.address, toWei(String(approveAmount), 'ether')).send({ from: defaultAccount, gasPrice: calculateGP }).once('receipt', () => {
              setIsApproved(countApproved + 1 === missingLength)
              setIsApprovingProcess(countApproved + 1 !== missingLength)
              countApproved++
            }).on('error', error => {
              setIsApprovingProcess(false)
              countApproved++
              return Catch('Can`t approve', error.message)
            })
          }
        }
      } catch ({ message }) {
        setIsApprovingProcess(false)
        return Catch('Can`t approve contract', message)
      }
    }
  }

  const approveButton = (text) => {
    return <button className={cn(styles.btn, styles.offset1, { 'btn-disabled': (isApprovingProcess) }, 'btn btn-gradient btn-gradient1 minHeight50')} onClick={makeApprove} disabled={isApprovingProcess}>
      {!isApprovingProcess && <span>{text}</span>}
      {isApprovingProcess && <span><img src="/images/icons/processing.svg" alt="" className={'spin_animation'}/> {processingText}</span>}
    </button>
  }

  return { isApproved, isApprovingProcess, makeApprove, checkApprove, approveButton }
}

/* Usage example:
const { isApproved, isApprovingProcess, makeApprove, checkApprove } = useApprove([{
    from: mineRunner721, // source contract that allow
    to: market, // destination contract for that allow
    amount: true, // if not a number that means approve for all vs approve selected amount convertet to WEI
    isErc20: false // if true load standard erc20, use with amount as number
  }])
 */
