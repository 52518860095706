import React from 'react'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'


export const MessageType = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  warningOrange: 'warningOrange',
}


const NotificationPlane = ({ children, title, description, className, type = MessageType.warningOrange }) => {

  let image

  switch (type) {
    case MessageType.success:
      image = 'success-primary'
      break
    case MessageType.error:
      image = 'fail'
      break
    case MessageType.warning:
      image = 'warning'
      break
    case MessageType.warningOrange:
      image = 'warning-orange'
      break
    default:
      return 'success-primary'
  }

  return (
    <div className={cn(styles.notification_plane, styles.flex_column, className)}>
      <img src={`/images/icons/${image}.svg`} className={cn(styles.icon_img)} alt=""/>
      {title && <h5 className={cn(styles.nl)}>{title}</h5> }
      {description && <p className={cn(styles.nl)}>{description}</p> }

      {children}
    </div>
  )
}

NotificationPlane.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
NotificationPlane.defaultProps = {
  title: 'The network is overloaded',
  description: 'Transaction might take a really long time. It may be worth trying later.',
}

export default NotificationPlane
