import React from 'react'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { ItemPlane } from './ItemPlane'

const InitScreen = ({ id, title, itemTitle, img, description, className }) => {
  return (
    <div className={cn(styles.flex_column, className)}>
      <h4 className={cn(styles.nl)}>{title}</h4>
      <p className={cn(styles.nl)}>{description}</p>
      <ItemPlane title={itemTitle} id={id ? `#${id}` : null} img={img}/>
    </div>
  )
}

InitScreen.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  itemTitle: PropTypes.string,
  img: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string
}

InitScreen.defaultProps = {
  id: null,
  title: 'Buy',
  itemTitle: 'Common Box',
  img: '/images/boxes/common.png',
  description: 'You are going to buy X token for $Y',
  className: null
}

export default InitScreen