import ConnectMetamask from '../../ConnectMetamask'
import cn from 'classnames'
import styles from '../Delist/Delist.module.scss'
import React, { useContext, useEffect } from 'react'
import { Web3Context } from '../../../contexts/Web3Context'
import Catch from '../../Catch'
import { toBN, toWei } from 'web3-utils'
import config from '../../../config'
import PropTypes from 'prop-types'
import { useApprove } from '../../../hooks/useApprove'
import { useStateIfMounted } from 'use-state-if-mounted'
import useOverload from '../../../hooks/useOverload'
import NotificationPlane from '../../Screens/NotificationPlane'

let timer = undefined

const PairingProcess = ({ father, mother, amountAMT,amountAZY, fail, success, processing, Classname }) => {
  const { mineRunner721, redeem, isConnected, isCorrectNetwork, defaultAccount, useSocket, web3 } = useContext(Web3Context)

  const { isApproved, approveButton, checkApprove } = useApprove([
    { from: mineRunner721, to: redeem, amount: true, isErc20: false },
    { from: config.tokens.AMT.options.address, to: redeem, amount: amountAMT, isErc20: true },
    { from: config.tokens.AZY.options.address, to: redeem, amount: amountAZY, isErc20: true },
  ])
  const { pairingEvents } = useSocket(defaultAccount)
  const [waiting, setWaiting] = useStateIfMounted()

  const { data: isNetOverloaded } = useOverload()

  const processSuccess = (text) => {
    clearTimeout(timer)
    processProcessing(false)
    if (typeof success === 'function') success(text)
  }

  const processFail = (message) => {
    processProcessing(false)
    if (typeof fail === 'function') fail(message)
    else Catch('Can`t mint', message)
  }

  const processProcessing = (state) => {
    if (typeof processing === 'function') processing(state)
  }
  useEffect(() => {
    if (!waiting) return
    timer = setTimeout(() => {
      processSuccess(<><h4 className="txt-center">Your mint took a little <br/>longer than expected. </h4><p className="txt-center">Don't worry, your sneakers box will appear <br/>in your inventory when
        blockchain <br/>transaction is processed.</p></>)
    }, 60 * 1000)

    return () => clearTimeout(timer)
    // eslint-disable-next-line
  }, [waiting])

  useEffect(() => {
    if (pairingEvents) {
      if (pairingEvents.failed === false) {
        processSuccess('Your new sneakers box arrived to your inventory')
      }
      if (pairingEvents.failed === true) {
        processFail(pairingEvents.data)
      }
    }// eslint-disable-next-line
  }, [pairingEvents])

  const pairingSubmit = async event => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    const check = await checkApprove()
    if (!check) {
      if (typeof fail === 'function') fail('Error on approve for mint')
      else Catch('Error on approve for mint')
    }
    processProcessing(true)

    try {
      const fee = await redeem.methods.typeInfo('pairing').call({ from: defaultAccount })
      const est = await redeem.methods.upgrade([father.tokenId, mother.tokenId], 'pairing', toWei(amountAMT, 'ether'), toWei(amountAZY, 'ether'), '').estimateGas({ from: defaultAccount, value: toBN(fee._price) ?? 0 })
      const balance = await web3.eth.getBalance(defaultAccount)
      const isEnough = toBN(balance).sub(toBN(fee._price)).sub(toBN(est)).gtn(0)
      if (!isEnough) return processFail('Not enough BNB on balance')
      const calculateGP = await web3.eth.getGasPrice()
      redeem.methods.upgrade([father.tokenId, mother.tokenId], 'pairing', toWei(amountAMT, 'ether'), toWei(amountAZY, 'ether'), '').send({ from: defaultAccount, value: toBN(fee._price) ?? 0, gasPrice: calculateGP }).once('receipt', () => {
        setWaiting(true)
      }).on('error', ({ message }) => {
        processFail(message)
      })
    } catch ({ message }) {
      processFail(message)
    }
  }

  return (father && mother &&
    <div className={cn(Classname)}>
      {(!isConnected || !isCorrectNetwork) && <ConnectMetamask/>}
      {isConnected && isCorrectNetwork &&
        <div className={styles.btn_container}>
          {isNetOverloaded && <NotificationPlane/>}
          {!isApproved && approveButton('Approve mint')}
          {isApproved && <button className={cn(styles.btn, styles.offset1, 'btn btn-gradient btn-gradient1')} onClick={pairingSubmit}><span>Mint</span></button>}
        </div>
      }
    </div>) || <></>
}

PairingProcess.propTypes = {
  father: PropTypes.object,
  mother: PropTypes.object,
  amountAMT: PropTypes.string,
  amountAZY: PropTypes.string,
  fail: PropTypes.func,
  success: PropTypes.func,
  processing: PropTypes.func
}

export default PairingProcess
