import React from 'react'
import styles from './ComingSoon.module.scss'
import {Grid} from '../../components/_UI/Grid/Grid'
import {useHistory} from 'react-router-dom'
import cn from 'classnames'
import {ItemImg} from '../../components/_UI/ItemImg/ItemImg'

const ComingSoon = () => {
  const history = useHistory();
  const goToHome = () => history.push('/');

  return (
    <div className={styles.coming}>


      <Grid>
        <div className={styles.coming__inner}>
          <ItemImg path={`/images/pages/soon/coming-soon-left.png`} alt={'coming soon'} width={662} className={styles.img_left} />
          <div className={styles.coming__content}>
            <span className={styles.title_container}>
              <ItemImg path={`/images/pages/soon/soon-icon.png`} alt={''} width={22} className={styles.img_icon} />
              <h2>Coming soon, really soon</h2>
            </span>
            <h4>Follow our <a href="https://linktr.ee/amazyio" target="_blank" rel="noreferrer noopener">social media</a> and get notified when the marketplace is launched.</h4>
            <button className={cn(styles.btn, 'btn ')} onClick={goToHome}>
            <span>
                Go Home
              </span>
            </button>
          </div>
          <ItemImg path={`/images/pages/soon/coming-soon-right.png`} alt={'coming soon'} width={582} className={styles.img_right} />
        </div>
      </Grid>
    </div>
  )
}

export default ComingSoon
