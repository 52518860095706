import React from 'react'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import AnimatedLoading from '../../../components/_UI/AnimatedLoading/AnimatedLoading'
import { NotificationPlane } from './NotificationPlane'

const LoadingScreenOverlay = ({ title, description, className, showNotify = true }) => {

  return (
    <div className={cn(styles.loading, styles.loading_overlay, styles.flex_column, className)}>
      <AnimatedLoading className={cn(styles.loader)}/>
      <h4 className={cn(styles.nl)}>{title}</h4>
      <p className={cn(styles.nl)}>{description}</p>

      {showNotify && <NotificationPlane icon={'/images/icons/warning.svg'} title={''} text={'Please wait for the switch to MetaMask and confirm the transaction'}/>}
    </div>
  )
}

LoadingScreenOverlay.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  showNotify: PropTypes.bool,
}
LoadingScreenOverlay.defaultProps = {
  title: 'Transaction is minting',
  description: 'It takes some time to mint a transaction',
  showNotify: true
}

export default LoadingScreenOverlay