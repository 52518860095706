import cn from 'classnames'
import styles from './styles.module.scss'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NotificationPlane } from './NotificationPlane'
import { isIOS } from 'react-device-detect'

const BackToApp = ({ path, status }) => {
  const [noDeepLinked, setNoDeepLinked] = useState(false)
  const [notificationIcon, setNotificationIcon] = useState('/images/icons/warning.svg')
  const [notificationTitle, setNotificationTitle] = useState('')
  const [notificationText, setNotificationText] = useState('Now go back to the AMAZY app and refresh the page.')

  const returnToApp = () => {
    window.open(path, '_blank')
    setTimeout(() => {
      setNoDeepLinked(true)
    }, 1000)
  }

  const setNotificationValues = (status) => {
    switch (status) {
      case 'success':
        setNotificationIcon('/images/icons/warning.svg')
        setNotificationTitle('')
        setNotificationText('Now go back to the AMAZY app and refresh the page.')
        break
      case 'fail':
        setNotificationIcon('/images/icons/warning.svg')
        setNotificationTitle('')
        setNotificationText('Something went wrong. Go back \nto the AMAZY app and try again.')
        break
      case 'long':
        setNotificationIcon('')
        setNotificationTitle('The response takes a bit longer')
        setNotificationText('That\' s fine. You can go back to the AMAZY app and refresh the page.')
        break
      default:
        setNotificationIcon('/images/icons/warning.svg')
        setNotificationTitle('')
        setNotificationText('Now go back to the AMAZY app and refresh the page.')
        break
    }
  }

  useEffect(() => {
    setNotificationValues(status)
    // eslint-disable-next-line
  }, [])

  return <>
    {(noDeepLinked || isIOS) && <NotificationPlane icon={notificationIcon} title={notificationTitle} text={notificationText}/>}
    {!noDeepLinked && !isIOS && <button className={cn(styles.btn, styles.btn_back, 'btn btn-gradient btn-gradient1 btn-back-app')} onClick={returnToApp}><span>Back to AMAZY app</span></button>}
  </>
}

BackToApp.propTypes = {
  path: PropTypes.string,
  status: PropTypes.string
}

BackToApp.defaultProps = {
  path: 'amazydl://app?scene=main',
  status: 'success',
}

export default BackToApp
