import React, { useContext, useState } from 'react'
import ConnectMetamask from '../../ConnectMetamask'
import cn from 'classnames'
import styles from './Delist.module.scss'
import { Web3Context } from '../../../contexts/Web3Context'
import Catch from '../../Catch'
import LoadingScreenOverlay from '../../../pages/AppRequests/Screens/LoadingScreenOverlay'
import PropTypes from 'prop-types'
import LoadingScreen from '../../../pages/AppRequests/Screens/LoadingScreen'
import Modal from '../../_UI/Modal'
import { isMobileOnly } from 'react-device-detect'
import useOverload from '../../../hooks/useOverload'
import NotificationPlane from '../../Screens/NotificationPlane'

const Delist = ({ token, success, fail, processing, showInnerStates }) => {
  const { market, defaultAccount, web3, isConnected, isCorrectNetwork } = useContext(Web3Context)
  const [isProcessing, setIsProcessing] = useState(false)
  const [mainModal, setMainModal] = useState(true)

  const { data: isNetOverloaded } = useOverload()

  const closePopup = () => {
    setMainModal(false)
  }

  const processSuccess = () => {
    processProcessing(false)
    if (typeof success === 'function') success()
  }

  const processFail = (text, message) => {
    processProcessing(false)
    if (typeof fail === 'function') fail(text)
    else Catch(text, message)
  }

  const processProcessing = (state) => {
    if (typeof processing === 'function') processing(state)
    if (showInnerStates) setIsProcessing(state)
  }

  const delistSubmit = async (event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    try {
      processProcessing(true)
      const calculateGP = await web3.eth.getGasPrice()
      market.methods.cancel(token.sellId).send({ from: defaultAccount, gasPrice: calculateGP }).once('receipt', () => {
        processSuccess()
      }).on('error', ({ message }) => {
        processFail('Can`t delist item', message)
      })
    } catch ({ message }) {
      processFail('Can`t delist item', message)
    }
  }

  return ((!isConnected || !isCorrectNetwork) && <ConnectMetamask/>) ||
    <>
      <button className={cn(styles.btn, 'btn btn-gradient btn-gradient1')} onClick={(event) => delistSubmit(event) && setMainModal(true)}>
        <span>Delist</span>
      </button>
      {isMobileOnly && showInnerStates && isProcessing && <LoadingScreenOverlay/>}

      {!isMobileOnly && showInnerStates && isProcessing &&
        <Modal visible={mainModal} onClose={() => closePopup()} size={'small'} onClickOutside={() => closePopup()}>
          <LoadingScreen/>
          {isNetOverloaded && <NotificationPlane/>}
        </Modal>
      }
    </>

}

Delist.propTypes = {
  token: PropTypes.object,
  success: PropTypes.func,
  fail: PropTypes.func,
  processing: PropTypes.func,
  showInnerStates: PropTypes.bool
}

export default Delist