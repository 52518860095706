import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import config from '../config'
import { Web3Context } from './Web3Context'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import configUI from '../config/configUI'
import { useLocation } from 'react-router-dom'
import useConfig from '../hooks/useConfig'

export const InventoryContext = createContext({})

const InventoryContextWrapper = ({ children }) => {
  const [inventoryCount, setInventoryCount] = useState(0)
  const [inventoryCountFiltered, setInventoryCountFiltered] = useState(0)
  const [inventoryCountPerType, setInventoryCountPerType] = useState(0)
  const [inventoryCountPerTypeFiltered, setInventoryCountPerTypeFiltered] = useState(0)
  const [restoreStorage, setRestoreStorage] = useState({
    market: {},
    inventory: {}
  })

  const { defaultAccount } = useContext(Web3Context)
  const location = useLocation()

  const { data: commonConfig } = useConfig()

  const defaultFilters = {
    page: 1,
    perPage: configUI.inventoryPerPage,
    rarity: [],
    valueMin: '',
    valueMax: '',
    sorting: 'Price ascending',
    skipOnSale: false,
    from: undefined,
  }
  const defaultFiltersSneakers = {
    ...defaultFilters,
    type: configUI.sneakers,
    levelMin: '',
    levelMax: '',
    sneakersType: [],
    mintMin: 0,
    mintMax: 7,
    performanceMin: '',
    performanceMax: '',
    fortuneMin: '',
    fortuneMax: '',
    joyMin: '',
    joyMax: '',
    durabilityMin: '',
    durabilityMax: '',
    basePerformanceMin: '',
    basePerformanceMax: '',
    baseFortuneMin: '',
    baseFortuneMax: '',
    baseJoyMin: '',
    baseJoyMax: '',
    baseDurabilityMin: '',
    baseDurabilityMax: '',
    genesis: false,
    nonGenesis: false,

  }

  const defaultFiltersBox = {
    ...defaultFilters,
    type: configUI.box,
    genesis: false,
    nonGenesis: false,
  }

  const defaultFiltersChip = {
    ...defaultFilters,
    type: configUI.chip,
    chipType: [],
  }

  const defaultFiltersChipBox = {
    type: configUI.chipBox,
    rarity: []
  }

  const isInventory = useMemo(() => {
    const p = location.pathname.split('/')
    return (p[1] === 'inventory' || p[1] === 'item')
  }, [location.pathname])

  const checkQS = useCallback(() => {
    const qs = queryString.parse(location.search)
    if (isInventory) {
      return {
        market: defaultFilters,
        inventory: { ...defaultFilters, ...qs }
      }
    } else {
      return {
        market: { ...defaultFilters, ...qs },
        inventory: defaultFilters
      }
    } // eslint-disable-next-line
  }, [location, isInventory])

  const [filters, setFilters] = useState(checkQS())

  const updateFilters = (f, defaultF = {}) => {
    if (isInventory) {
      if (Object.keys(defaultF).length > 0) setFilters({ market: filters.market, inventory: { ...defaultF, ...f } })
      else setFilters({ market: filters.market, inventory: { ...filters.inventory, ...f } })
    } else {
      if (Object.keys(defaultF).length > 0) setFilters({ inventory: filters.inventory, market: { ...defaultF, ...f } })
      else setFilters({ inventory: filters.inventory, market: { ...filters.market, ...f } })
    }
  }

  useEffect(() => {
    if (defaultAccount) updateFilters({ from: defaultAccount })
    // eslint-disable-next-line
  }, [defaultAccount])

  const resetFilters = (type) => {
    const f = getTypeDefault(type)

    if (defaultAccount) f.from = defaultAccount
    if (isInventory) setFilters({ market: filters.market, inventory: f })
    else setFilters({ inventory: filters.inventory, market: f })
  }

  const getTypeDefault = (type) => {
    let f
    switch (type) {
      case configUI.box:
        f = defaultFiltersBox
        break
      case configUI.sneakers:
        f = defaultFiltersSneakers
        break
      case configUI.chip:
        f = defaultFiltersChip
        break
      case configUI.chipBox:
        f = defaultFiltersChipBox
        break
      default:
        f = defaultFilters
        break
    }
    return f
  }

  const updateInventoryCount = async () => {
    try {
      if (filters.inventory.from != null || defaultAccount) {
        const tItems = await axios.get(config.backendEndpoint + 'inventory/?total=1&from=' + (filters.inventory.from ?? defaultAccount))
        if (tItems.data) {
          setInventoryCount(+tItems.data.total)
          setInventoryCountPerType(tItems.data.type)

          if (!inventoryCountFiltered) {
            setInventoryCountFiltered(+tItems.data.total)
          }
          if (!inventoryCountPerTypeFiltered) {
            setInventoryCountPerTypeFiltered(tItems.data.type)
          }
        }
      }
    } catch ({ message }) {
      console.error(message)
    }
  }

  const updateInventoryFilteredCount = async () => {
    try {
      if (filters.inventory.from) {
        const filterString = queryString.stringify(filters.inventory)
        const tItems = await axios.get(`${config.backendEndpoint}inventory/?total=1&${filterString}`)
        if (tItems.data) {
          setInventoryCountFiltered(+tItems.data.total)
          setInventoryCountPerTypeFiltered(tItems.data.type)
        }
      }
    } catch ({ message }) {
      console.error(message)
    }
  }

  const restore = (source) => {
    return restoreStorage?.[source] ?? {}
  }

  const setRestore = (source, data) => {
    if (typeof restoreStorage[source] === 'undefined') return
    setRestoreStorage({ ...restoreStorage, [source]: data })
  }

  return (
    <InventoryContext.Provider
      value={{
        filters,
        defaultFiltersBox,
        defaultFiltersSneakers,
        defaultFiltersChip,
        resetFilters,
        updateFilters,
        inventoryCount,
        inventoryCountPerType,
        inventoryCountFiltered,
        inventoryCountPerTypeFiltered,
        setInventoryCount,
        setInventoryCountPerType,
        commonConfig,
        restore,
        setRestore,
        getTypeDefault,
        updateInventoryCount,
        updateInventoryFilteredCount
      }}
    >
      {children}
    </InventoryContext.Provider>
  )
}

InventoryContextWrapper.propTypes = {
  children: PropTypes.any
}

export default InventoryContextWrapper
