import React from 'react'
import Buy from '../../components/_Operations/Buy'
import InitScreen from './Screens/InitScreen'
import BackToApp from './Screens/BackToApp'

const BuyFrame = ({ success }) => {
  return (
    <>
      <InitScreen description={'You are going to buy Common Box'} itemTitle={'Common Box'}/>
      <Buy callback={success} customBuyText={true}/>
      <BackToApp path={'amazydl://app?scene=inventory'} status={'success'} />
    </>
  )
}

export default BuyFrame
