import React, { useContext, useState } from 'react'
import ConnectMetamask from '../../ConnectMetamask'
import Modal from '../../_UI/Modal'
import cn from 'classnames'
import styles from './Sell.module.scss'
import { Web3Context } from '../../../contexts/Web3Context'
import Catch from '../../Catch'
import { ItemImg } from '../../_UI/ItemImg/ItemImg'
import { toWei } from 'web3-utils'
import config from '../../../config'
import { useHistory } from 'react-router-dom'
import { useStateIfMounted } from 'use-state-if-mounted'
import ItemName from '../../_UI/ItemName/ItemName'
import ToolTipCustom from '../../../components/_UI/ToolTipCustom'
import configUI from '../../../config/configUI'
import { useApprove } from '../../../hooks/useApprove'
import LoadingScreen from '../../../pages/AppRequests/Screens/LoadingScreen'
import PropTypes from 'prop-types'
import NotificationPlane from '../../Screens/NotificationPlane'
import useOverload from '../../../hooks/useOverload'

const Sell = ({ token, success, fail, processing, showInnerStates, onClose }) => {
  const { mineRunner721, market, defaultAccount, web3, isConnected, isCorrectNetwork } = useContext(Web3Context)
  const [mainModal, setMainModal] = useState(false)
  const [sellPrice, setSellPrice] = useState('')
  const [step, setStep] = useState(1)
  const [bscScan, setBscScan] = useStateIfMounted('#"')

  const history = useHistory()
  const sellBtnDisabled = token.type === configUI.sneakers && (+token?.primaryProperties?.['Condition'] !== 100 || +token.levelupTimeout > 0)

  const { isApproved, checkApprove, approveButton } = useApprove([{ from: mineRunner721, to: market, amount: true, isErc20: false }])

  const { data: isNetOverloaded } = useOverload()

  const processSuccess = (txUri) => {
    setBscScan(txUri)
    processProcessing(false)
    if (showInnerStates) setStep(3)
    if (typeof success === 'function') success()
  }

  const processFail = (message) => {
    setStep(1)
    processProcessing(false)
    if (typeof fail === 'function') fail(message)
    else Catch('Can`t sell item', message)
  }

  const processProcessing = (state) => {
    if (typeof processing === 'function') processing(state)
    if (showInnerStates && state) setStep(2)
  }

  const sellSubmit = async (event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    const check = await checkApprove()
    if (!check) {
      if (typeof fail === 'function') fail('Error on approve for sell')
      else Catch('Error on approve for sell')
    }
    processProcessing(true)

    try {
      const weiPrice = toWei(`${sellPrice}`, 'ether')

      const calculateGP = await web3.eth.getGasPrice()
      market.methods.sell(mineRunner721.options.address, token.tokenId, weiPrice).send({ from: defaultAccount, gasPrice: calculateGP }).once('receipt', receipt => {
        processSuccess(config.explorer + '/tx/' + receipt.transactionHash)
      }).on('error', ({ message }) => processFail(message))
    } catch ({ message }) {
      processFail(message)
    }
  }

  const updateSellPrice = (event) => {
    let val = event.target.value
    const re = /^(\d*)([,.]\d{0,2})?$/
    if (re.test(val)) {
      if (val.slice(-1) === '.') {
        setSellPrice(val)
        return
      }
      if (/^(\d*)([,.]0)?$/.test(val)) {
        setSellPrice(val)
        return
      }
      let result = parseFloat(val)
      if (isNaN(+result)) result = 0
      if (+result > +config.maxSellPrice) result = config.maxSellPrice
      setSellPrice(result)
    }
  }

  const goToInventory = () =>  history.push('/inventory') && closeSellPopup()

  const openModal = () => {
    if (sellBtnDisabled) return
    setStep(1)
    setMainModal(true)
  }

  const closeSellPopup = () => {
    processProcessing(false)
    setStep(1)
    setMainModal(false)
    if (typeof onClose === 'function') onClose(true)
  }

  return (
    <>
      {(!isConnected || !isCorrectNetwork) && <ConnectMetamask/>}
      {isConnected && isCorrectNetwork && <>
        {!isApproved && approveButton('Approve for sell')}
        {isApproved &&
          <button className={cn(styles.btn, 'btn btn-gradient btn-gradient1 minHeight50', sellBtnDisabled && 'btn-disabled')} onClick={openModal}>
            <span>List to market{sellBtnDisabled && <img src="/images/icons/lock.svg" alt=""/>}</span>
            {sellBtnDisabled && +token?.primaryProperties?.['Condition'] !== 100 && <ToolTipCustom className="tooltip-show-on-mobile_ mobileSpHide">You can’t list an item with Condition less than 100</ToolTipCustom>}
            {sellBtnDisabled && +token.levelupTimeout > 0 && <ToolTipCustom className="tooltip-show-on-mobile_ mobileSpHide">You can’t list an item while it leveled</ToolTipCustom>}

              {sellBtnDisabled && +token?.primaryProperties?.['Condition'] !== 100 && <span className="tooltip-show-on-mobile-span">You can’t list an item with Condition less than 100</span>}
              {sellBtnDisabled && +token.levelupTimeout > 0 && <span className="tooltip-show-on-mobile-span">You can’t list an item while it leveled</span>}
          </button>
        }
      </>}
      <Modal visible={mainModal} onClose={() => closeSellPopup()} size={'small'} onClickOutside={() => closeSellPopup()}>
        {(!isConnected || !isCorrectNetwork) && <ConnectMetamask/>}

        {isConnected && isCorrectNetwork &&
          <div className={styles.content}>

            {/*----------Start Step1 Sell----------*/}
            {step === 1 &&
              <div className={styles.flex_container}>
                <div className={styles.col2}>
                  {token && <>
                    <div>
                      <span className={styles.id}>id#{token.tokenId}</span>
                    </div>
                    <h4><ItemName item={token}/></h4>
                  </>}

                  <ItemImg path={{ type: token.type, ...token.primaryProperties }} width={320} alt={token.type} className={styles.img_big} srcSet={false}/>

                  <div className={styles.input_container}>
                    <div className={styles.inp_col1}>
                      <input type="text" autoFocus placeholder="Set sell price" value={sellPrice} onChange={updateSellPrice}/>
                    </div>
                    <div className={styles.inp_col2}>
                      <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png" alt=""/>
                      <span>{config.defaultMarketPlaceSymbol}</span>
                    </div>
                  </div>

                  {isNetOverloaded && <NotificationPlane/>}

                  <button className={cn(styles.btn, 'btn btn-gradient ', { 'btn-disabled': !(parseFloat(sellPrice) > 0) })} onClick={sellSubmit} disabled={!(parseFloat(sellPrice) > 0)}>
                    <span>Confirm</span>
                  </button>

                </div>
              </div>
            }
            {/*----------End Step1 Sell----------*/}

            {step === 2 && <LoadingScreen/>}

            {/*----------Start Step3 Sell----------*/}
            {step === 3 &&
              <div className={styles.container}>
                <img src="/images/icons/success-primary.svg" className={cn(styles.icon_img)} alt=""/>
                <h4>Transaction submitted</h4>
                <p>You can see items that you <br/>listed on the marketplace. </p>

                <button className={cn(styles.btn, 'btn btn-gradient')} onClick={goToInventory}>
                  <span>Inventory</span>
                </button>
                <a href={bscScan} target="_blank" rel="noreferrer noopener" className={cn(styles.scan_link)}>
                  View on BSCscan
                  <i className="icon_font icon-arr-right"/>
                </a>
              </div>
            }
            {/*----------End Step3 Sell----------*/}
          </div>
        }
      </Modal>
    </>
  )
}

Sell.propTypes = {
  token: PropTypes.object,
  success: PropTypes.func,
  fail: PropTypes.func,
  processing: PropTypes.func,
  showInnerStates: PropTypes.bool,
  onClose: PropTypes.func
}

export default Sell
