import React from 'react'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import BackToApp from './BackToApp'

const SuccessScreen = ({ title, description, className, path }) => {

  return (
    <div className={cn(styles.flex_column, className)}>
      <img src="/images/icons/success-primary.svg" className={cn(styles.icon_img)} alt=""/>
      <h4 className={cn(styles.nl)}>{title}</h4>
      {typeof description === 'string' && <p className={cn(styles.nl)}>{description}</p>}
      {typeof description !== 'string' && description}

      <BackToApp path={path} status={description && typeof description === 'string' && description.includes('longer than expected') ? 'long' : 'success'}/>
    </div>
  )
}

SuccessScreen.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  path: PropTypes.string
}
SuccessScreen.defaultProps = {
  title: 'Purchase successful',
  description: 'You can see purchased \n items in inventory',
  className: '',
  path: 'amazydl://app?scene=main'
}

export default SuccessScreen