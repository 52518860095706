import { useQuery } from 'react-query'
import axios from 'axios'
import config from '../config'
import configUI from '../config/configUI'
import Catch from '../components/Catch'

const useConfig = () => {
  const getConfig = async () => {

    try {
      const tItem = await axios.get(config.backendEndpoint + 'game/config')

      return tItem?.data?.data ?? {}
    } catch ({ message }) {
      Catch('Can`t get config', message)
    }
  }

  return useQuery(['commonConfig'], getConfig, {
    staleTime: configUI.configExpireMinutes * 60 * 1000,
    keepPreviousData: true
  })
}

export default useConfig
