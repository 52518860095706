import React from 'react'
import { Grid } from '../_UI/Grid/Grid'
import styles from './FooterMobile.module.scss'
import cn from 'classnames'

export const FooterMobile = () => {
  return (
    <footer className={styles.footer}>
      <Grid>
        <section>
          <div className={cn(styles.footer__bottom)}>
            <div>
              <p>© 2022 - all rights reserved 丨 powered <br/>by carmellow invest limited</p>
              <p>By using our website, you agree to our <br/><a href="https://amazy.io/privacypolicy" target="_blank" rel="noreferrer noopener">Privacy Policy</a> and our <a href="https://amazy.io/cookiepolicy" target="_blank" rel="noreferrer noopener">Cookies Policy</a>
              </p>
            </div>
          </div>
        </section>
      </Grid>
    </footer>
  )
}
