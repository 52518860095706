import styles from '../../components/LayoutMobile/LayoutMobile.module.scss'

const MobileUnsupported = () => {
    return (
        <>
            <img src="/images/logo.svg" alt="Amazy" className={styles.logo}/>
            <h3>Amazy Marketplace does not support mobile devices
            </h3>
            <div className="align-center">
                <img src="/images/use-desktop.svg" alt="Use-desktop"/>
            </div>
            <p>To trade on Amazy Marketplace please visit this page using your desktop or laptop device.</p>
        </>
    )
}

export default MobileUnsupported
