import cn from 'classnames'
import styles from './SwitchToMetamaskBrowser.module.scss'
import { useLocation } from 'react-router-dom'

const SwitchToMetamaskBrowser = () => {
  const location = useLocation()

  const handleSwitch = async () => {
    window.open(
      'https://metamask.app.link/dapp/' + window.location.host + location.pathname + location.search,
      '_blank' // <- This is what makes it open in a new window.
    )
  }

  return (
    <div>
      <h4 className={cn('h4', styles.title)}>Switch To <br/>Metamask Browser</h4>
      <div className={styles.text}>
        Please Switch To Metamask Browser To Be Able To Work With Site
      </div>
      <div className={styles.btns}>
        <button className={cn('btn', styles.button)} onClick={handleSwitch}>Switch To Metamask Browser</button>
      </div>
    </div>
  )
}

export default SwitchToMetamaskBrowser
