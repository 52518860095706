import { useQuery } from 'react-query'
import axios from 'axios'
import config from '../config'
import Catch from '../components/Catch'

const useItem = (tokenId) => {

  const getItems = async () => {
    if (tokenId) {
      try {
        const tItem = await axios.get(config.backendEndpoint + 'item/' + tokenId)
          .catch(({ message }) => console.error(message))
        if (tItem?.data?.data) {
          return tItem.data.data
        } else Catch(tItem?.data?.error ?? 'Can`t get item')
        return false
      } catch ({ message }) {
        console.error(message)
        return false
      }
    }

    return []
  }

  return useQuery(['item', tokenId], getItems, { enabled: false })
}

export default useItem
