import React from 'react'
import styles from './FirstVisitPopup.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'

export const FirstVisitPopup = ({ onClose }) => {

  return (
    <div className={cn(styles.FirstVisitPopup_container,)}>
      <div className={cn(styles.img_container)}>
        <img src={`/images/pages/firsttime/sneakers.png`} alt={'Sneakers'} width={504} srcSet={undefined}/>
      </div>

      <h4>Start using AMAZY in 4 steps</h4>
      <ul className={styles.list_num}>
        <li>
          <h5>Connect Wallet</h5>
          <p>Press <span className={styles.highlight}>Login with MetaMask or any other wallet</span> via Wallet Connect feature in the top right corner</p>
        </li>
        <li>
          <h5>Signup for AMAZY account</h5>
          <p>Enter your e-mail address and come up with a password</p>
        </li>
        <li>
          <h5>Buy sneakers</h5>
          <p>Choose a suitable box or pair of sneakers in the official AMAZY Marketplace and purchase it</p>
        </li>
        <li>
          <h5>Download AMAZY App</h5>
          <p>Log in to your account and start earning</p>
        </li>
      </ul>

      <button type="submit" className={cn(styles.btn, 'btn btn-gradient btn-gradient1 ')} onClick={onClose}><span>Let's go</span></button>

    </div>
  )
}

FirstVisitPopup.propTypes = {
  onClose: PropTypes.func,
}


