import React, { Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import ComingSoon from './pages/ComingSoon/ComingSoon'
import LoadSite from './components/_UI/LoadSite/LoadSite'

const Inventory = React.lazy(() => import('./pages/Inventory/Inventory'))
const Marketplace = React.lazy(() => import('./pages/Marketplace/Marketplace'))
const SingleItem = React.lazy(() => import('./pages/SingleItem'))
const Page404 = React.lazy(() => import('./pages/Page404/Page404'))
const Activities = React.lazy(() => import('./pages/Main/Activities'))
const Invites = React.lazy(() => import('./pages/Main/Invites'))
const SignIn = React.lazy(() => import('./components/SignIn/SignIn'))
const SignUp = React.lazy(() => import('./components/Popup/Popup'))
const PasswordRecovery = React.lazy(() => import('./components/PassswordRecovery/PasswordRecovery'))
const Profile = React.lazy(() => import('./pages/Profile/Profile'))
const Wallet = React.lazy(() => import('./pages/Wallet/Wallet'))
const DepositTokens = React.lazy(() => import('./pages/DepositTokens/DepositTokens'))
const DeleteAccountFrame = React.lazy(() => import('./pages/AppRequests/DeleteAccount'))

export const createRoutes = () => (
  <Suspense fallback={<LoadSite/>}>
    <Switch>
      <Route exact path="/" component={Marketplace}/>

      <Route exact path="/game/delete-account">
        <DeleteAccountFrame/>
      </Route>

      <Route exact path="/about">
        <ComingSoon/>
      </Route>

      <Route exact path="/marketplace/:mode">
        <Marketplace/>
      </Route>

      <Route exact path="/marketplace">
        <Marketplace/>
      </Route>

      {false && <Route exact path="/marketplace">
        <ComingSoon/>
      </Route>}

      <Route exact path="/login">
        <SignIn/>
      </Route>

      <Route exact path="/register/:signup?">
        <SignUp/>
      </Route>

      <Route exact path="/recover">
        <PasswordRecovery/>
      </Route>

      {/*<Route exact path="/activation">
        <ActivationCode/>
      </Route>*/}

      <Route exact path="/profile">
        <Profile/>
      </Route>

      <Route exact path="/wallet">
        <Wallet/>
      </Route>

      <Route exact path="/deposit">
        <DepositTokens/>
      </Route>

      <Route exact path="/soon">
        <ComingSoon/>
      </Route>

      <Route exact path="/how-to-buy">
        <ComingSoon/>
      </Route>

      <Route exact path="/main/activities">
        <Activities/>
      </Route>

      <Route exact path="/main/invites">
        <Invites/>
      </Route>

      {/*<Route exact path="/boxes">
        <Boxes/>
      </Route>

      <Route exact path="/boxes/:rarity/:sid?">
        <Boxes/>
      </Route>*/}

      <Route path="/inventory/:mode">
        <Inventory/>
      </Route>

      <Route exact path="/inventory">
        <Inventory/>
      </Route>

      <Route exact path="/item/:id">
        <SingleItem/>
      </Route>

      {/*<Route exact path="/:sid" component={Home}/>*/}
      <Route exact path="/:sid" component={Marketplace}/>

      <Route path="/page/404" component={Page404}/>
      <Redirect to="/page/404"/>

    </Switch>
  </Suspense>
)
