import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import styles from './User.module.scss'
import { Web3Context } from '../../../contexts/Web3Context'
import config from '../../../config'
import { InventoryContext } from '../../../contexts/InventoryContext'
import CopyButton from './../../_UI/CopyButton'
import AnimatedLoading from './../../_UI/AnimatedLoading/AnimatedLoading'
import { message } from 'antd'

import axios from 'axios'
import Catch from '../../Catch'

const User = ({ className, isActivated, isAddressBound = false }) => {
  const [visible, setVisible] = useState(false)
  const [notify, setNotify] = useState(false)

  // const { Countdown } = Statistic

  const { disconnect, shortenAddress, defaultAccountBalance, defaultAccount, useSocket } = useContext(Web3Context)
  const { inventoryCount, updateInventoryCount, setInventoryCount } = useContext(InventoryContext)

  const [totalInvites, setTotalInvites] = useState(200)
  const [leftInvites, setLeftInvites] = useState(0)
  // const [reset, setReset] = useState(0)
  const [processed, setProcessed] = useState(false)
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const location = useLocation()

  const { updates } = useSocket(defaultAccount)

  const checkNotify = () => {
    const exists = localStorage.getItem('sneakers-notify-store-' + defaultAccount)

    if (location.pathname === '/inventory') {
      localStorage.setItem('sneakers-notify-store-' + defaultAccount, inventoryCount)
      setNotify(false)
      return true
    }

    if (inventoryCount > 0) {
      if (!exists) {
        setNotify(true)
      }
      if (parseInt(exists) < inventoryCount) {
        setNotify(true)
      }
    }
  }

  // eslint-disable-next-line
  useEffect(() => defaultAccount && updateInventoryCount(), [defaultAccount, location])

  useEffect(() => {
    if (updates.countItems != null) {
      setInventoryCount(+updates.countItems)
    }
    // eslint-disable-next-line
  }, [updates])

  // eslint-disable-next-line
  useEffect(checkNotify, [inventoryCount, location])

  const checkLeftInvites = async () => {
    if (!isActivated) {
      try {
        const result = await axios.get(`${config.backendEndpoint}check-invites-left`)
        setTotalInvites(result?.data?.data?.total ?? 200)
        setLeftInvites(result?.data?.data?.left ?? 0)
        // setReset(result?.data?.data?.delta ?? 12 * 60 * 60)
      } catch ({ message }) {
        Catch(message)
      }
    }
  }

  // eslint-disable-next-line
  useEffect(() => checkLeftInvites(), [isActivated, defaultAccount])

  const processActivating = async () => {
    try {
      setLoading(true)
      await axios.post(`${config.backendEndpoint}activate-invite`, { from: defaultAccount })
      setProcessed(true)
      setLoading(false)
      message.success('Your account was successfully activated')
    } catch ({ message }) {
      setLoading(false)
      Catch(message)
    }
  }

  // const countdownFinished = async () => {
  //   await checkLeftInvites()
  // }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>

      <div className={cn(styles.user, className)}>

        {!isActivated && !processed && leftInvites > 0 && isAddressBound &&
          <div className={cn(styles.activation_container)}>
            <button className={styles.copy} onClick={processActivating} disabled={loading}>
              <p className={cn(styles.get_code)}>Activate your account</p>
              <p className={cn(styles.code_descr)}>{leftInvites}/{totalInvites} codes issued</p>
              {loading && <div className={cn(styles.loader)}><AnimatedLoading/></div>}
            </button>
          </div>
        }

        {/*--------- Show when timer to Next activation codes -----------*/}
        {/*{!isActivated && !processed && leftInvites === 0 && <div className={cn(styles.activation_container, styles.disabled)}>
          <p className={cn(styles.get_code)}>Next activation codes</p>
          <div className={cn(styles.timer_descr)}>release in&nbsp;
            <Countdown value={Date.now() + reset * 1000} onFinish={countdownFinished} className={cn(styles.countdown)}/>
          </div>
        </div>}*/}

        {defaultAccount.length > 0 && !isAddressBound &&
          <button onClick={() => history.push('/register')} className={cn(styles.btn, styles.btn_signup, 'btn')}>
            <span className={styles.txt_light}>Sign Up</span>
          </button>
        }

        <div className={cn(styles.head, visible && styles.opened)} onClick={() => setVisible(!visible)}>

          {/*------------ Notification Icon, show when new items appeared in inventory -------------*/}
          {notify && <span className={cn(styles.user_label, 'label-num label-big')}>
            <img src="/images/icons/notification.svg" alt="notify"/>
          </span>}

          <div className={styles.col}>
            <div className={styles.row_balance}>
              <div className={styles.icon}>
                <img src="/images/icons/busd.svg" alt="coin icon"/>
              </div>
              <span className={styles.value}>{defaultAccountBalance} {config.symbolName}</span>
            </div>
            <div className={styles.row_address}>
              <span>{shortenAddress(defaultAccount)}</span>
              <CopyButton text={defaultAccount}/>
            </div>
          </div>

        </div>

        {visible && ( //menu
          <div className={styles.body}>
            <div className={styles.menu}>

              <Link className={styles.item} to="/inventory" onClick={() => setVisible(!visible)}>
                <div className={styles.text}>
                  <img src="/images/icons/inventory.svg" alt=""/>
                  Inventory {inventoryCount > 0 &&
                  <span className="label-num label-small">{inventoryCount}</span>}
                </div>
              </Link>

              {/*<Link className={styles.item} to="/transactions" onClick={() => setVisible(!visible)}>
                <div className={styles.text}>
                  <img src="/images/icons/transactions-2.svg" alt=""/>
                  My transactions
                </div>
              </Link>*/}

              {isAddressBound && <>
                <Link className={styles.item} to="/wallet" onClick={() => setVisible(!visible)}>
                  <div className={styles.text}>
                    <img src="/images/icons/wallet.svg" alt=""/>
                    Wallet
                  </div>
                </Link>

                <Link className={styles.item} to="/deposit" onClick={() => setVisible(!visible)}>
                  <div className={styles.text}>
                    <img src="/images/icons/deposit.svg" alt=""/>
                    Deposit tokens
                  </div>
                </Link>

                <Link className={styles.item} to="/profile" onClick={() => setVisible(!visible)}>
                  <div className={styles.text}>
                    <img src="/images/icons/settings.svg" alt=""/>
                    Profile
                  </div>
                </Link>
              </>}

              <button onClick={disconnect}>
                <img src="/images/icons/disconnect.svg" alt=""/>
                Disconnect
              </button>

            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  )
}

export default User
