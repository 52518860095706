import React from "react";
import cn from "classnames";
import styles from "./ToolTipCustom.module.scss";
import tail from './images/popup-tail.svg';
import warning from './images/warning.svg';

const ToolTipCustom = ({
           children, className, showIcon = true,
         }) => {

  return (
    <div className={cn(styles.btn_tooltip, "btn-tooltip", className)}>
          <span className={styles.tooltip_tail}>
            <img src={tail} alt=""/>
          </span>

          { showIcon &&
              <span className={styles.tooltip_icon}>
                <img src={warning} alt=""/>
              </span>
          }

         <span className={styles.tooltip_txt}>
           {children}
         </span>
    </div>
  );


};

export default ToolTipCustom;
