import { QueryClient } from 'react-query'
import Catch from '../components/Catch'

export const queryErrorHandler = error => {
  const title =
    error instanceof Error
      ? error.toString().replace(/^Error:\s*/, '')
      : 'Error connecting to server'
  Catch(title)
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: queryErrorHandler,
      refetchOnWindowFocus: false,
    },
  },
})
