import React, { useContext, useEffect } from 'react'
import InitScreen from './Screens/InitScreen'
import useItem from '../../hooks/useItem'
import { getItemName } from '../../components/_UI/ItemName/ItemName'
import Buyout from '../../components/_Operations/Buyout'
import { Web3Context } from '../../contexts/Web3Context'
import configUI from '../../config/configUI'

const BuyoutFrame = ({ success, fail, processing, tokenId }) => {
  const { data } = useItem(tokenId)

  const { defaultAccount } = useContext(Web3Context)

  useEffect(() => {
    if (defaultAccount && data) {
      if (!data.sellId) fail('Token not on sale')
      if (defaultAccount === data.owner) fail('You can’t buy your own token')
    }
  }, [fail, data, defaultAccount])

  return (data &&
    <>
      <InitScreen description={'You are purchasing token from the marketplace'} id={parseInt(tokenId)} itemTitle={getItemName(data)}
                  img={data.type === configUI.sneakers ? `/images/sneakers/${data?.primaryProperties?.Rarity}/${data?.primaryProperties?.Type}/${data?.primaryProperties?.Skin}.png` : undefined}/>
      <Buyout token={data} success={() => success('You can see purchased token in inventory')} fail={fail} processing={processing}/>
    </>) || <></>
}

export default BuyoutFrame
