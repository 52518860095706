import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import MobileUnsupported from '../MobileUnsupported/MobileUnsupported'
import { Web3Context } from '../../contexts/Web3Context'
import BindAddress from './BindAddress'
import Redeem from './Redeem'
import SellFrame from './Sell'
import Claim from './Claim'
import BuyFrame from './Buy'
import BuyoutFrame from './Buyout'
import DelistFrame from './Delist'
import SuccessScreen from './Screens/SuccessScreen'
import ErrorScreen from './Screens/ErrorScreen'
import LoadingScreen from './Screens/LoadingScreen'
import LoadingScreenOverlay from './Screens/LoadingScreenOverlay'
import PairingFrame from './Pairing'
import config from '../../config'
import { fromWei } from 'web3-utils'

const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const AppRequests = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isInitScreen, setIsInitScreen] = useState(true)
  let backPath = 'amazydl://app?scene=main'

  const [errorText, setErrorText] = useState()
  const [successText, setSuccessText] = useState()
  const [workedAcc, setWorkedAcc] = useState()

  const { isConnected, isCorrectNetwork, defaultAccount, getErc20Contract } = useContext(Web3Context)

  const query = useQuery()
  const jwt = query.get('jwt')
  const reqType = query.get('reqType')
  const tokenId = (query.get('tokenId') ?? '').replace(/\D+/, '')
  let amount = query.get('amount')
  let amountAMT = query.get('amountAMT')
  let amountAZY = query.get('amountAZY')
  const father = (query.get('father') ?? '').replace(/\D+/, '')
  const mother = (query.get('mother') ?? '').replace(/\D+/, '')
  const currency = (query.get('currency') ?? '').toUpperCase()
  const from = query.get('from')
  if (amount) amount = amount.replace(',', '.')
  if (amountAMT) amountAMT = amountAMT.replace(',', '.')
  if (amountAZY) amountAZY = amountAZY.replace(',', '.')

  const checkQueryProps = async () => {
    let checked = true

    if (!['buy', 'bind', 'unbind', 'redeem', 'claim', 'sell', 'buyout', 'delist', 'pairing'].includes(reqType)) {
      fail('Invalid operation')
      checked = false
    }

    if (query.get('tokenId') && tokenId < 0) {
      fail('Invalid token')
      checked = false
    }

    if (currency && !['AMT', 'AZY'].includes(currency)) {
      fail('Invalid currency')
      checked = false
    }

    if (amount && parseFloat(amount) <= 0) {
      fail('Invalid amount')
      checked = false
    }

    if (from && from !== defaultAccount) {
      fail('Your app address different from default MetaMask address')
      checked = false
    }

    if (amount > 0) {
      let cur = (currency && currency === 'AZY') ? currency : 'AMT'
      if (reqType === 'buy') cur = config.defaultToken
      const contr = getErc20Contract(config.tokens[cur].options.address)
      if (contr) {
        const balance = await contr.methods.balanceOf(defaultAccount).call()
        if (parseFloat(fromWei(balance, 'ether')) < parseFloat(amount)) {
          fail(`Not enough ${cur} on balance`)
          checked = false
        }
      } else {
        fail('Error on MetaMask connecting')
        checked = false
      }
    }
    return checked
  }

  useEffect(() => {
    ;(async () => {
      if (defaultAccount && await checkQueryProps() && workedAcc !== defaultAccount) {
        setWorkedAcc(defaultAccount)
        setIsLoading(false)
        setIsError(false)
        setIsSuccess(false)
        setIsInitScreen(true)
      }
    })()// eslint-disable-next-line
  }, [defaultAccount, isConnected, isCorrectNetwork, getErc20Contract])

  const processing = value => {
    setIsLoading(value)
  }

  const humanizeFail = value => {
    if (value.includes('insufficient funds')) {
      return 'Not enough BNB to process'
    }

    if (value.includes('amount exceeds')) {
      return 'Not enough tokens to process'
    }

    if (value.includes('insufficient allowance')) {
      return 'Not enough tokens to process'
    }

    if (value.includes('gas required exceeds allowance')) {
      return 'Not enough BNB to process'
    }

    if (value.includes('query for nonexist')) {
      return 'Your NFT already processed, please be patient'
    }

    if (value.includes('Not your token')) {
      return 'You should be owner to process'
    }

    return value
  }

  const fail = value => {
    if (value) {
      setIsInitScreen(false)
      setErrorText(humanizeFail(value))
      setIsLoading(false)
      setIsError(true)
      setIsSuccess(false)
    }
  }

  const success = (text) => {
    setIsInitScreen(false)
    setSuccessText(text)
    setIsLoading(false)
    setIsError(false)
    setIsSuccess(true)
  }

  let content
  switch (reqType) {
    case 'test':
      /// content = <>
      // <Input placeholder="put deep link" onChange={setDL} value={deepLink}/><br/><br/><br/>
      // <Link to={{ pathname: deepLink }} target={'_blank'}>GOTOAPP {deepLink}</Link><br/> <br/>
      //   <Button onClick={testBtn}> GOTOAPPBTN {deepLink}</Button>
      //    <br/><br/>
      // <CopyToClipboard text={deepLink}><p className="copy-clipboard">GOTOAPPCOPYBTN {deepLink}</p></CopyToClipboard>
      // </>
      break
    case 'buy':
      content = <BuyFrame success={success}/>
      backPath = 'amazydl://app?scene=inventory'
      break
    case 'bind':
    case 'unbind':
      content = <BindAddress jwt={jwt} success={success} unbind={reqType === 'unbind'} processing={processing} fail={fail}/>
      break
    case 'redeem':
      content = <Redeem tokenId={tokenId} success={success} fail={fail}/>
      backPath = 'amazydl://app?scene=inventory'
      break
    case 'claim':
      content = <Claim jwt={jwt} success={success} fail={fail} processing={processing}/>
      break
    case 'sell':
      content = <SellFrame success={success} fail={fail} processing={processing} tokenId={tokenId}/>
      backPath = 'amazydl://app?scene=inventory'
      break
    case 'buyout':
      content = <BuyoutFrame success={success} fail={fail} processing={processing} tokenId={tokenId}/>
      backPath = 'amazydl://app?scene=inventory'
      break
    case 'delist':
      content = <DelistFrame success={success} fail={fail} processing={processing} tokenId={tokenId}/>
      backPath = 'amazydl://app?scene=inventory'
      break
    case 'pairing':
      if (father <= 0 || mother <= 0) fail('Invalid parents')
      content = <PairingFrame success={success} fail={fail} processing={processing} fatherId={father} motherId={mother} amountAMT={amountAMT} amountAZY={amountAZY}/>
      backPath = 'amazydl://app?scene=inventory'
      break
    default:
      content = <MobileUnsupported/>
      break
  }

  return (window.ethereum && <>
    {isSuccess && <SuccessScreen title={'Operation successful'} description={successText} path={backPath}/>}
    {isError && <ErrorScreen title={'Operation failed'} description={errorText} path={backPath}/>}
    {/*{isLoading && <LoadingScreen title={'Transaction is minting'} description={'It takes some time to mint a transaction'}/>}*/}
    {isLoading && <LoadingScreenOverlay title={'Transaction is minting'} description={'It takes some time to mint a transaction'} showNotify={true}/>}
    {isInitScreen && content}
  </>) || <LoadingScreen title={'Waiting'} description={'Wait while MetaMask initializing..'}/>

}

export default AppRequests