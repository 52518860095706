import {message} from 'antd';

message.config({top: 20, duration: 5, maxCount: 3});
const humanizeMsg = msg => {
  if (Array.isArray(msg) && msg.includes('Not your token')) return 'Not your token'
  return msg
}
const Catch = (mgs, error = false) => {
  if (!error) error = mgs;
  console.error(error);
  message.error(humanizeMsg(mgs));
  return true;
};

export default Catch;