import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { createRoutes } from './routes'
import { Layout } from './components/Layout/Layout'
import Web3ContextWrapper from './contexts/Web3Context'
import InventoryContextWrapper from './contexts/InventoryContext'
import { queryClient } from './config/queryClient'
import { QueryClientProvider } from 'react-query'
import { LayoutMobile } from './components/LayoutMobile/LayoutMobile'
import { isMobileOnly } from 'react-device-detect'
import AppRequests from './pages/AppRequests/AppRequests'
import DeleteAccountFrame from './pages/AppRequests/DeleteAccount'
import AuthContextWrapper from './contexts/AuthContext'

function App () {
  const routes = createRoutes()
  const loc = window.location.pathname.replaceAll('/', '')
  return (
    <>
      <Router>
        <QueryClientProvider client={queryClient}>
          <AuthContextWrapper>
            <Web3ContextWrapper>
              <InventoryContextWrapper>
                {['application-requests', 'gamedelete-account'].includes(loc) && isMobileOnly ?
                  <LayoutMobile>
                    <Route exact path="/application-requests">
                      <AppRequests/>
                    </Route>
                    <Route exact path="/game/delete-account">
                      <DeleteAccountFrame/>
                    </Route>
                  </LayoutMobile>
                  :
                  <Layout>{routes}</Layout>
                }
              </InventoryContextWrapper>
            </Web3ContextWrapper>
          </AuthContextWrapper>
        </QueryClientProvider>
      </Router>
    </>
  )
}

export default App
