import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Screens/styles.module.scss'
import axios from 'axios'
import config from '../../config'
import Catch from '../../components/Catch'
import {Grid} from '../../components/_UI/Grid/Grid';
import SuccessScreen from './Screens/SuccessScreen'
import ErrorScreen from './Screens/ErrorScreen'
import LoadingScreenOverlay from './Screens/LoadingScreenOverlay'
import { useLocation } from 'react-router-dom'

const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const DeleteAccountFrame = () => {
  const [code, setCode] = useState('')
  const [sent, setSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isInitScreen, setIsInitScreen] = useState(true)
  let backPath = 'amazydl://app?scene=main'
  const [errorText, setErrorText] = useState()
  const [successText, setSuccessText] = useState()

  const query = useQuery()
  const email = query.get('email')

  const fail = value => {
    if (value) {
      setIsInitScreen(false)
      setErrorText(value)
      setIsLoading(false)
      setIsError(true)
      setIsSuccess(false)
    }
  }

  const processing = value => {
    setIsLoading(value)
  }

  const success = (text) => {
    setIsInitScreen(false)
    setSuccessText(text)
    setIsLoading(false)
    setIsError(false)
    setIsSuccess(true)
  }

  const updateCode = (event) => {
    setCode(event.target.value)
  }

  const sendConfirmation = async (event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    processing('Please wait')
    try {
      const result = await axios.post(config.backendEndpoint + 'app-delete-account-request', { email })
      processing(false)
      if (result?.data?.ok === true) {
        setSent(true)
      } else {
        processing(false)
        fail(result?.data?.error ?? 'Can`t send confirmation code to delete account, please try again later')
      }
    } catch (e) {
      processing(false)
      fail(e?.response?.data?.error?.[0]?.msg ?? 'Something went wrong, please try again later')
    }
  }

  const confirmDeleteAccount = async (event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    if (code.length !== 6) return Catch('Invalid code')

    processing('Please wait')
    try {
      const result = await axios.post(config.backendEndpoint + 'app-delete-account-confirm', { email, code })
      processing(false)
      if (result?.data?.ok === true) {
        success('Account successfully deleted')
      } else {
        processing(false)
        fail(result?.data?.error ?? 'Can`t delete account, please try again later')
      }
    } catch (e) {
      processing(false)
      fail(e?.response?.data?.error?.[0]?.msg ?? 'Something went wrong, please try again later')
    }
  }

  return (
    <>
      <Grid>
        {isSuccess && <SuccessScreen title={'Operation successful'} description={successText} path={backPath}/>}
        {isError && <ErrorScreen title={'Operation failed'} description={errorText} path={backPath}/>}
        {isLoading && <LoadingScreenOverlay title={'Operation processing'} description={'It takes some time, please wait'} showNotify={false}/>}
        {isInitScreen && email && <>
          <div className={cn(styles.flex_column)}>
            <h4 className={cn(styles.nl)}>Delete Account</h4>
            <p className={cn(styles.nl)}>You are going to delete your account</p>
            <div>
              <span className={styles.email_txt}>{email}</span>
            </div>
          </div>

          {!sent &&
            <div className={cn(styles.txt_center)}>
              <br/>
              <button className={cn(styles.btn, 'btn btn-gradient ')} onClick={sendConfirmation}>
                <span>Send Confirm Code to Email</span>
              </button>
            </div>
          }

          {sent &&
            <div className={cn(styles.txt_center)}>
              <br/>
              <p className={cn(styles.nl, styles.warn_txt)}>Your data will be completely deleted.</p>
              <div className={cn(styles.delete_form)}>
                <div className={styles.input_container}>
                  <div className={styles.inp_col1}>
                    <input type="text" placeholder="Enter confirmation code" value={code} onChange={updateCode}/>
                  </div>
                </div>
                <div>
                  <button className={cn(styles.btn, 'btn btn-gradient ')} onClick={confirmDeleteAccount}>
                    <span>Confirm Delete Account</span>
                  </button>
                </div>
              </div>

            </div>
          }
        </>}
      </Grid>
    </>
  )
}

export default DeleteAccountFrame
