import React, { useContext, useState } from 'react'
import cn from 'classnames'
import styles from './Buyout.module.scss'
import PropTypes from 'prop-types'
import fromExponential from 'from-exponential'
import Catch from '../../Catch'
import config from '../../../config'
import { Web3Context } from '../../../contexts/Web3Context'
import LoadingScreenOverlay from '../../Screens/LoadingScreenOverlay'
import LoadingScreen from '../../Screens/LoadingScreen'
import NotificationPlane from '../../Screens/NotificationPlane'
import ConnectMetamask from '../../ConnectMetamask'
import Modal from '../../_UI/Modal'
import { isMobileOnly } from 'react-device-detect'
import { fromWei } from 'web3-utils'
import ucfirst from 'ucfirst'
import useOverload from '../../../hooks/useOverload'
import MessageScreen, { MessageType } from '../../Screens/MessageScreen'
import { useHistory } from 'react-router-dom'

const Buyout = ({ token, success, fail, processing }) => {
  const { isConnected, isCorrectNetwork, web3, market, defaultAccount } = useContext(Web3Context)
  const [isProcessing, setIsProcessing] = useState(false)
  const [mainModal, setMainModal] = useState(true)
  const history = useHistory()
  const [currentFrame, setCurrentFrame] = useState('loading')
  const [errorText, setErrorText] = useState('Can`t buy item')

  const { data: isNetOverloaded } = useOverload()

  const closeSellPopup = () => {
    setMainModal(false)
    setIsProcessing(false)
    if (typeof success === 'function') success()
  }

  const processSuccess = () => {
    setCurrentFrame('success')
  }

  const processFail = (text, message) => {
    setErrorText(text)
    setCurrentFrame('error')
    if (typeof fail === 'function') fail(text)
    else Catch(text, message)
  }

  const processProcessing = (state) => {
    if (typeof processing === 'function') processing(state)
  }

  const buySubmit = async (event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    setIsProcessing(true)
    setMainModal(true)
    setCurrentFrame('loading')
    processProcessing(true)
    try {
      const calculateGP = await web3.eth.getGasPrice()
      market.methods.buy(token.sellId).send({ from: defaultAccount, value: token.price, gasPrice: calculateGP }).once('receipt', () => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          ecommerce: {
            currencyCode: config.defaultMarketPlaceSymbol,
            'checkout': {
              'actionField': {
                'step': 2,
                'option': 'Price'
              },
              'items': [{
                'item_name': token.name,
                'item_id': token.tokenId, // id item,
                'item_brand': token?.primaryProperties?.Type ?? '', // [Hiker]-[Ranger]-[Coacher]-[Sprinter]
                'item_category': ucfirst(token.type), // [Sneakers]-[Boxes]
                'item_category2': token?.primaryProperties?.Performance ?? '', // [PERFORMANCE]
                'item_category3': token?.primaryProperties?.Fortune ?? '', // [FORTUNE]
                'item_category4': token?.primaryProperties?.Joy ?? '', // [JOY]
                'item_category5': token?.primaryProperties?.Durability ?? '', // [DURABILITY]
                'item_category6': token?.primaryProperties?.Mint ?? '', // [MINT]
                'item_category7': token?.level ?? 0, // [LEVEL]
                'item_category8': token?.genesis, // [GENESIS]
                'item_variant': token?.primaryProperties?.Rarity ?? '', // [Common]-[UNCommon]-[Rare]-[Epic]-[Legendary]
                'price': fromWei(String(token.price), 'ether'), // BNB перевод в USD
                'quantity': 1 // количество монет = 1 всегда

              }]
            }
          },
          event: 'CustomEvent',
          category: 'BuyItem',
          action: 'ButtonBuyfor',
          'non-interaction': 'False',
          platform: token.primaryProperties?.Type, // [Hiker]-[Ranger]-[Coacher]-[Sprinter]
          coins: 1, // количество монет = 1 всегда,
          products: ucfirst(token.type), // [Sneakers]-[Boxes]
          userId: defaultAccount, // [номер кошелька Metamask]
          coupon: '' // Invite Code при активации
        })
        processSuccess()
      }).on('error', ({ message }) => {
        processFail('Can`t buy item', message)
      })
    } catch ({ message }) {
      processFail('Can`t process buy item', message)
    }
  }

  return ((!isConnected || !isCorrectNetwork) && <ConnectMetamask/>) ||
    <>

      <button className={cn(styles.btn, 'btn btn-gradient btn-gradient1')} onClick={buySubmit}>
        <span>Buy for <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png" alt=""/> {fromExponential(token.priceEth)} {token.currency ?? config.defaultMarketPlaceSymbol}</span>
      </button>

      {isMobileOnly && isProcessing && <LoadingScreenOverlay/>}

      {!isMobileOnly && isProcessing &&
        <Modal visible={mainModal} onClose={() => closeSellPopup()} size={'small'} onClickOutside={() => closeSellPopup()}>

          {/*----------- Start Error Screen --------*/}
          {currentFrame === 'error' && <>
            <MessageScreen
              type={MessageType.error}
              title={'Purchase Failed'}
              btnText={'Close'}
              description={errorText}
              onClose={closeSellPopup}
            />
          </>
          }
          {/*----------- End Error Screen --------*/}

          {/*----------- Start Success Screen --------*/}
          {currentFrame === 'success' && <>
            <MessageScreen
              type={MessageType.success}
              title={'Purchase successful'}
              description={'You can see purchased \nitems in inventory'}
              btnText={'Inventory'}
              onClose={() => history.push('/inventory')}
            >
            </MessageScreen>
          </>
          }
          {/*----------- End Success Screen --------*/}

          {/*----------- Start Loading Screen --------*/}
          {currentFrame === 'loading' && <>
            <LoadingScreen/>
          </>
          }
          {/*----------- End Loading Screen --------*/}

          {isNetOverloaded && <NotificationPlane/>}
        </Modal>
      }

    </>
}

Buyout.propTypes = {
  token: PropTypes.object,
  success: PropTypes.func,
  fail: PropTypes.func,
  processing: PropTypes.func
}

export default Buyout
