import React from 'react'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import BackToApp from './BackToApp'

const ErrorScreen = ({ title, description, className, path }) => {

  return (
    <div className={cn(styles.flex_column, className)}>
      <img src="/images/icons/fail.svg" className={cn(styles.icon_img)} alt=""/>
      <h4 className={cn(styles.nl)}>{title}</h4>
      {typeof description === 'string' && <p className={cn(styles.nl)}>{description}</p>}
      {typeof description !== 'string' && description}
      <BackToApp path={path} status={'fail'} />
    </div>
  )
}

ErrorScreen.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  path: PropTypes.string
}
ErrorScreen.defaultProps = {
  title: 'Purchase failed',
  description: '%Error text%',
  className: '',
  path: 'amazydl://app?scene=main'
}

export default ErrorScreen