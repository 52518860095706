import React, { useContext } from 'react'
import { Web3Context } from '../../contexts/Web3Context'
import styles from './ConnectMetamask.module.scss'
import cn from 'classnames'
import SwitchNetwork from './SwitchNetwork'
import SwitchToMetamaskBrowser from './SwitchToMetamaskBrowser'
import Modal from '../_UI/Modal'
import { useStateIfMounted } from 'use-state-if-mounted'
import { isMobile } from 'react-device-detect'

const ConnectMetamask = ({longTxt=false}) => {

  const [showSwitch, setShowSwitch] = useStateIfMounted(false)
  const [showSwitchMetamask, setShowSwitchMetamask] = useStateIfMounted(false)

  const { isConnected, isCorrectNetwork, connectWeb3 } = useContext(Web3Context)

  /*useEffect(() => { // todo: если будет гденить кнопка добавить токен в список ММ ибо ММ не дает проверить имеется ли уже такой токен в спиське
    (async () => {
      if (isConnected && isCorrectNetwork && !isTokenAdded) {
        console.log('isConnectedS', isConnected);
        console.log('isCorrectNetworkS', isCorrectNetwork);
        try {
          if (config.isTokenPayment) {
            await window.ethereum.request({
              method: 'wallet_watchAsset',
              params: config.token,
            });
          }
          setIsTokenAdded(true);
        } catch (e) {
          console.log(e.message);
          return false;
        }
      }
    })();
  }, [isConnected, isCorrectNetwork, isTokenAdded]);*/

  const connectMM = async (event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    try {
      if (isMobile && !window.ethereum) {
        return setShowSwitchMetamask(true)
      }
      let conn = isConnected
      if (!isConnected) {
        conn = await connectWeb3()
      }

      if (conn && !isCorrectNetwork) setShowSwitch(true)
    } catch (e) {
      return false
    }
  }

  return (
    <>
        <button onClick={connectMM} className={cn(styles.btn, 'btn')}>
          {/*Connect Wallet*/}
            {!longTxt && 'Connect Wallet'}
            {longTxt && 'Login with MetaMask wallet'}
          <img src="/images/icons/metamask.svg" alt="" className="icon"/>
        </button>

      {isConnected && !isCorrectNetwork &&
        <Modal visible={showSwitch} onClose={() => setShowSwitch(false)} onClickOutside={() => {
          setShowSwitch(false)
        }}>
          <SwitchNetwork/>
        </Modal>
      }

      <Modal visible={showSwitchMetamask} onClose={() => setShowSwitchMetamask(false)} onClickOutside={() => {
        setShowSwitchMetamask(false)
      }}>
        <SwitchToMetamaskBrowser/>
      </Modal>

    </>
  )
}

export default ConnectMetamask
