import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './OpenBox.module.scss'
import cn from 'classnames'
import SneakersOpenedItem from './SneakersOpenedItem/SneakersOpenedItem'
import { Web3Context } from '../../../contexts/Web3Context'
import ConnectMetamask from '../../ConnectMetamask'
import Catch from '../../Catch'
import { toBN } from 'web3-utils'
import { useApprove } from '../../../hooks/useApprove'
import Modal from '../../_UI/Modal'
import { useHistory } from 'react-router-dom'
import NotificationPlane from '../../Screens/NotificationPlane'
import useOverload from '../../../hooks/useOverload'
import { useTimer } from 'react-timer-hook'
import MessageScreen, { MessageType } from '../../Screens/MessageScreen'

const OpenBox = ({ token, success, fail, processing, showInnerStates, onClose }) => {
  const [mainModal, setMainModal] = useState(false)
  const { defaultAccount, mineRunner721, isConnected, isCorrectNetwork, redeem, useSocket, web3 } = useContext(Web3Context)
  const [showBoxSection, setShowBoxSection] = useState(false)
  const [showSneakersSection, setShowSneakersSection] = useState(false)
  const [showFailSection, setShowFailSection] = useState(false)

  const { isApproved, approveButton, checkApprove } = useApprove([{ from: mineRunner721, to: redeem, amount: true, isErc20: false }])
  const { redeemEvents } = useSocket(defaultAccount)
  const history = useHistory()

  const { data: isNetOverloaded } = useOverload()

  const {
    restart: restartSetWaitingTimer,
  } = useTimer({
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: () => {
      setShowBoxSection(false)
      setShowSneakersSection(false)
      setShowFailSection(true)
    },
  })

  const processSuccess = (text) => {
    processProcessing(false)
    if (typeof success === 'function') success(text)
  }

  const processFail = (message) => {
    processProcessing(false)
    if (typeof fail === 'function') fail(message)
    else Catch(message)
    setMainModal(false)
  }

  const processProcessing = (state) => {
    if (typeof processing === 'function') processing(state)
    if (state === true) {
      setShowBoxSection(true)
      setMainModal(true)
    }
  }

  const closeOpenPopup = () => {
    setMainModal(false)
    processProcessing(false)
    if (typeof onClose === 'function') onClose(true)
  }

  useEffect(() => {
    if (redeemEvents) {
      if (token.tokenId != null && redeemEvents.from != null && redeemEvents.from === token.tokenId) {
        restartSetWaitingTimer(new Date(), false)
        if (redeemEvents.failed) {
          processFail(redeemEvents.data)
        } else {
          processSuccess(innerSuccessSection(redeemEvents.data))
          if (showInnerStates) setShowSneakersSection(redeemEvents.data)
        }
      }
    }
    // eslint-disable-next-line
  }, [token, redeemEvents])

  const goToInventory = () => history.push('/inventory/sneakers')

  const openBoxSubmit = async event => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    const check = await checkApprove()
    if (!check) {
      if (typeof fail === 'function') fail('Error on approve for open')
      else Catch('Error on approve for open')
    }
    processProcessing(true)

    try {
      const fee = await redeem.methods.typeInfo('redeem').call({ from: defaultAccount })
      const est = await redeem.methods.upgrade([token.tokenId], 'redeem', 0, 0, '').estimateGas({ from: defaultAccount, value: toBN(fee._price) ?? 0 })
      const balance = await web3.eth.getBalance(defaultAccount)
      const isEnough = toBN(balance).sub(toBN(fee._price)).sub(toBN(est)).gtn(0)
      if (!isEnough) return processFail('Not enough BNB on balance')
      const calculateGP = await web3.eth.getGasPrice()
      await redeem.methods.upgrade([token.tokenId], 'redeem', 0, 0, '').send({ from: defaultAccount, value: toBN(fee._price) ?? 0, gasPrice: calculateGP }).once('receipt', () => {
        const time = new Date()
        time.setSeconds(time.getSeconds() + 30)
        restartSetWaitingTimer(time)
      }).on('error', ({ message }) => {
        processFail('Can`t open box', message)
      })
    } catch ({ message }) {
      processFail('Can`t open box', message)
    }
  }

  const innerSuccessSection = (item) => {
    return item && <div className={cn(styles.heroes_section, 'sneakers_section')}>
      <h4 className="txt-center">Your box drop is ready <br/>to be claimed</h4>
      <div className={styles.heroes_container}>
        <SneakersOpenedItem item={item} className={styles.sneakers_item}/>
      </div>
      {showInnerStates && <div className={styles.btn_container}>
        <button className={cn(styles.btn, 'btn btn-gradient btn-gradient1')} onClick={goToInventory}>
          <span>Go To Inventory</span>
        </button>
      </div>}
    </div>
  }

  return (
    <>
      {(!isConnected || !isCorrectNetwork) && <ConnectMetamask/>}
      {isConnected && isCorrectNetwork && <>
        {!isApproved && approveButton('Approve for open')}
        {isApproved && <button className={cn(styles.btn2, 'btn btn-gradient  btn-outline minHeight50 ')} onClick={openBoxSubmit}><span>Open</span></button>}
      </>}
      <Modal visible={mainModal} onClose={() => closeOpenPopup()} size={'small'} onClickOutside={() => closeOpenPopup()}>
        <div className={styles.content}>
          {/*----------Start Processing Section----------*/}
          {showBoxSection && !showSneakersSection &&
            <div className={cn(styles.chest_section)}>

              <div className={cn(styles.chest_img,)}>
                <video width="406" height="188" autoPlay={true} loop preload="auto">
                  <source src={`/images/video/${token?.primaryProperties?.Rarity.toLowerCase()}.webm`}
                          type="video/webm"/>
                </video>
              </div>

              <h4>Opening <br/>{token?.primaryProperties?.Rarity} Box #{token.tokenId}</h4>
              <p>After blockchain transaction is processed, new sneakers will appear in your inventory</p>

              {isNetOverloaded && <NotificationPlane className={styles.notificationPlane}/>}

              <button className={cn(styles.btn, 'btn btn-gradient btn-gradient1', 'btn-disabled')}>
                <span>
                  <img src="/images/icons/processing.svg" alt="" className={'spin_animation'}/>
                  Processing
                </span>
              </button>
            </div>
          }
          {/*----------End Processing Section----------*/}

          {showSneakersSection && innerSuccessSection(showSneakersSection)}


          {/*----------- Start Error Screen --------*/}
          {showFailSection && <>
            <MessageScreen
              type={MessageType.error}
              title={'Your summon took a little \n longer than expected.'}
              description={'Don\'t worry, your sneakers will appear \n in your inventory when blockchain \n transaction is processed.'}
              onClose={() => history.push('/inventory/sneakers')}
            />
          </>
          }
          {/*----------- End Error Screen --------*/}


        </div>
      </Modal>
    </>
  )

}

OpenBox.propTypes = {
  token: PropTypes.object,
  success: PropTypes.func,
  fail: PropTypes.func,
  processing: PropTypes.func,
  showInnerStates: PropTypes.bool,
  onClose: PropTypes.func
}

export default OpenBox
