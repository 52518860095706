import React, { useContext, useEffect, useState } from 'react'
import InitScreen from './Screens/InitScreen'
import useItem from '../../hooks/useItem'
import Sell from '../../components/_Operations/Sell'
import { getItemName } from '../../components/_UI/ItemName/ItemName'
import configUI from '../../config/configUI'
import { Web3Context } from '../../contexts/Web3Context'

const SellFrame = ({ success, fail, processing, tokenId }) => {
  const { data } = useItem(tokenId)
  const { defaultAccount, isConnected } = useContext(Web3Context)
  const [processed, setProcessed] = useState(0)

  useEffect(() => {
    if (defaultAccount && isConnected && data && tokenId !== processed) {
      setProcessed(tokenId)
      if (!data.type) fail('Token not found')
      if (data.sellId) fail('Token already on sale')
      if (data.owner !== defaultAccount) {
        if (typeof fail === 'function') fail('You should be owner to process minting')
      }
    } // eslint-disable-next-line
  }, [isConnected, data])

  return (
    <>
      {data?.type && <>
        <InitScreen description={'You are going to list token on the marketplace'} id={data.tokenId} title={'Sell'} itemTitle={getItemName(data)}
                    img={data.type === configUI.sneakers ? `/images/sneakers/${data?.primaryProperties?.Rarity}/${data?.primaryProperties?.Type}/${data?.primaryProperties?.Skin}.png` : undefined}/>
        <Sell token={data} success={() => success('You can see placed token in marketplace')} fail={fail} processing={processing} showInnerStates={true}/>
      </>}
    </>
  )
}

export default SellFrame
