import { useQuery } from 'react-query'
import axios from 'axios'
import config from '../config'
import configUI from '../config/configUI'
import Catch from '../components/Catch'

const useOverload = () => {
  const getOverload = async () => {

    try {
      const tItem = await axios.get(config.backendEndpoint + 'is-network-overloaded')

      return !!tItem?.data?.data
    } catch ({ message }) {
      Catch('Can`t get network overload', message)
    }
  }

  return useQuery(['isNetworkOverloaded'], getOverload, {
    staleTime: configUI.overloadExpireMinutes * 60 * 1000,
    keepPreviousData: true
  })
}

export default useOverload
