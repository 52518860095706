const configUI = {
  // UI config
  currentSale: 'common',
  activeSales: ['common', 'uncommon', 'rare'],
  box: 'box',
  chipBox: 'chipBox',
  sneakers: 'sneakers',
  chip: 'chip',
  inventoryPerPage: 12,
  overloadExpireMinutes: 5,
  sneakersMaxLevel: 30,
  configExpireMinutes: 30,
  chipLevel: {
    Common: 1,
    Uncommon: 2,
    Rare: 3,
    Epic: 4,
    Legendary: 5,
    Amazy: 6
  },
  reverseChipLevel: {
    1: 'Common',
    2: 'Uncommon',
    3: 'Rare',
    4: 'Epic',
    5: 'Legendary',
    6: 'Amazy'
  },
  speedByType: {
    Ranger: '1-6',
    Hiker: '4-10',
    Sprinter: '8-20',
    Coacher: '1-20',
  },
  stars: {
    Common: 1,
    Uncommon: 2,
    Rare: 3,
    Epic: 4,
    Legendary: 5
  },
  // Sneakers characteristics to use on box page
  boxPageItems: {
    common: {
      ranger: {
        performance: '4',
        fortune: '7',
        joy: '2',
        durability: '10',
        chance: '3% '
      },
      hiker: {
        performance: '2',
        fortune: '10',
        joy: '6',
        durability: '4',
        chance: '3% '
      },
      coacher: {
        performance: '7',
        fortune: '9',
        joy: '1',
        durability: '10',
        chance: '3% '
      },
      sprinter: {
        performance: '3',
        fortune: '7',
        joy: '6',
        durability: '9',
        chance: '3% '
      }
    },
    uncommon: {
      ranger: {
        performance: '4',
        fortune: '7',
        joy: '2',
        durability: '10',
        chance: '3% '
      },
      hiker: {
        performance: '2',
        fortune: '10',
        joy: '6',
        durability: '4',
        chance: '3% '
      },
      coacher: {
        performance: '7',
        fortune: '9',
        joy: '1',
        durability: '10',
        chance: '3% '
      },
      sprinter: {
        performance: '3',
        fortune: '7',
        joy: '6',
        durability: '9',
        chance: '3% '
      }
    },
    rare: {
      ranger: {
        performance: '4',
        fortune: '7',
        joy: '2',
        durability: '10',
        chance: '3% '
      },
      hiker: {
        performance: '2',
        fortune: '10',
        joy: '6',
        durability: '4',
        chance: '3% '
      },
      coacher: {
        performance: '7',
        fortune: '9',
        joy: '1',
        durability: '10',
        chance: '3% '
      },
      sprinter: {
        performance: '3',
        fortune: '7',
        joy: '6',
        durability: '9',
        chance: '3% '
      }
    }
  }
}

configUI['defaultFilterType'] = configUI.sneakers

export default configUI
