import React from "react";
import cn from "classnames";
import styles from './LoadSite.module.scss'
import AnimatedLoading from '../AnimatedLoading/AnimatedLoading';

const LoadSite = ({className}) => {
  return (
    <div className={cn(styles.load_site, className)}>
          <span>
            <AnimatedLoading/>
          </span>
          <span> Loading...</span>
    </div>
  );
};

export default LoadSite;

