import PropTypes from 'prop-types'
import configUI from '../../../config/configUI'

export const getItemName = item => {
  let result = []

  if (item?.primaryProperties?.Rarity) {
      result.push(item.primaryProperties.Rarity + '\n')
  }

  if (item?.primaryProperties?.Type && item.type !== configUI.box) {
    result.push(item.primaryProperties.Type + '\n')
  }

  if (item.type) {
    //result.push(ucfirst(item.type))
  }

  return result.join(' ')
}

const ItemName = ({ item }) => getItemName(item)

ItemName.prototype = {
  item: PropTypes.object
}

export default ItemName