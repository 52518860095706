import React, { useContext, useEffect } from 'react'
import InitScreen from './Screens/InitScreen'
import useItem from '../../hooks/useItem'
import { getItemName } from '../../components/_UI/ItemName/ItemName'
import Delist from '../../components/_Operations/Delist'
import { Web3Context } from '../../contexts/Web3Context'
import configUI from '../../config/configUI'

const DelistFrame = ({ success, fail, processing, tokenId }) => {
  const { data } = useItem(tokenId)

  const { defaultAccount } = useContext(Web3Context)

  useEffect(() => {
    if (defaultAccount && data) {
      if (!data.sellId) fail('Token not on sale')
      if (defaultAccount !== data.owner) fail('You can’t delist not own token')
    }
  }, [fail, data, defaultAccount])

  return (
    <>
      {data && <InitScreen title={'Delist'} description={'You are delist token from the marketplace'} id={parseInt(tokenId)} itemTitle={getItemName(data)}
                           img={data.type === configUI.sneakers ? `/images/sneakers/${data?.primaryProperties?.Rarity}/${data?.primaryProperties?.Type}/${data?.primaryProperties?.Skin}.png` : null}/>}
      {data && <Delist token={data} success={() => success('You can see delisted token in inventory')} fail={fail} processing={processing}/>}
    </>
  )
}

export default DelistFrame
