import ConnectMetamask from '../../components/ConnectMetamask'
import cn from 'classnames'
import styles from './Screens/styles.module.scss'
import React, { useContext, useEffect } from 'react'
import { Web3Context } from '../../contexts/Web3Context'
import axios from 'axios'
import config from '../../config'

const BindAddress = ({ success, processing, fail, jwt, unbind = false }) => {
  const { defaultAccount, shortenAddress, signMgs, isConnected, isCorrectNetwork } = useContext(Web3Context)

  useEffect(() => {
    ;(async () => {
      if (defaultAccount) {
        try {
          const result = await axios.get(`${config.backendEndpoint}check-is-address-bound?jwt=${jwt}&from=${defaultAccount}`)
          if (result?.data?.data?.exists) {
            if (!unbind) {
              fail('Address already bound')
            }
          } else {
            if (unbind) {
              fail('Address not bound')
            }
          }
        } catch (e) {
          fail('Something went wrong, please try again later')
        }
      }
    })() // eslint-disable-next-line
  }, [defaultAccount, jwt])

  const signBind = async () => {
    try {
      processing(true)

      const sign = await signMgs({ jwt, defaultAccount })
      if (!sign) {
        fail('Bind message does not signed')
        return
      }

      const result = await axios.post(config.backendEndpoint + 'app-request', {
        reqType: unbind ? 'unbind' : 'bind',
        from: defaultAccount,
        sign,
        jwt
      })

      processing(false)
      if (result?.data?.ok === true) {
        success(unbind ? 'Successfully unbound' : 'Successfully bound')
      } else {
        fail(result?.data?.error?.[0]?.msg)
      }
    } catch ({ message }) {
      processing(false)
      fail(message)
    }
  }

  return (
    <div className="bind-comp-container">
      <div className={cn(styles.flex_column)}>
        <h4 className={cn(styles.nl)}>{unbind ? 'Unbind Address' : 'Bind Address'}</h4>
        <p className={cn(styles.nl)}>{'The current Metamask address will be bound to your account'}</p>
        <div className={cn(styles.flex_column, styles.address_section)}>
          <img src={'/images/icons/metamask.svg'} alt=""/>
          {defaultAccount && <h5 className={cn(styles.nl)}><span>{shortenAddress(defaultAccount)}</span></h5>}
        </div>
      </div>
      <div>
        {(!isConnected || !isCorrectNetwork) && <ConnectMetamask/>}
        {isConnected && isCorrectNetwork && <button className={cn(styles.btn, 'btn btn-gradient btn-gradient1')} onClick={signBind}><span>{unbind ? 'Unbind Address' : 'Bind Address'}</span></button>}
      </div>
    </div>
  )
}

export default BindAddress
