import {useState} from 'react';
import config from '../../../config';
import cn from 'classnames';
import styles from './SwitchNetwork.module.scss';

const SwitchNetwork = () => {

  const [error, setError] = useState(false);

  const handleSwitch = async () => {
    const defaultMetamaskNetworks = [1, 3, 4, 5, 42];
    const message = `Please change your wallet network to the ${config.networkName} : ${config.networkId} network ID Manually!`;

    if (!defaultMetamaskNetworks.includes(config.networkId)) {
      if (window.ethereum) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [config.network],
          });
        } catch (e) {
          setError(e.message);
          return false;
        }
      }
    } else {
      setError(message);
      return false;
    }
  };

  return (
      <div>
        <h4 className={cn('h4', styles.title)}>Switch Network</h4>
        <div className={styles.text}>
          Please Connect Your Wallet To The <strong>{config.networkName}</strong> To Be Able To Work With Site
        </div>
        {error && <div className="error">{error}</div>}
        {!error && <div className={styles.btns}>
          <button className={cn('btn', styles.button)} onClick={handleSwitch}>Switch To {config.networkName}</button>
        </div>}
      </div>
  );
};

export default SwitchNetwork;
