import { numberToHex } from 'web3-utils'

const config = {
  // System config
  defaultAllowanceAmount: process.env.REACT_APP_ALLOWANCE_AMOUNT || '10000',
  maxSellPrice: process.env.REACT_APP_MAX_SELL_PRICE || '10000000',
  seasonId: process.env.REACT_APP_SALE_SEASON || '1',
  // isTokenPayment: process.env.REACT_APP_SALE_BY_TOKEN || 0,
  backendEndpoint: process.env.REACT_APP_BACKEND_ENDPOINT || 'http://localhost:5041/',
  socketEndpoint: process.env.REACT_APP_SOCKET_ENDPOINT || 'http://localhost:5041/',
  isDebug: process.env.REACT_APP_DEBUG_ROUTES === 'true' || false,
  isFeature: process.env.REACT_APP_IS_FEATURE === 'true' || false,
  // Coin and network config
  networkName: process.env.REACT_APP_NETWORK_NAME || 'Binance Smart Chain Network',
  networkNameWC: process.env.REACT_APP_NETWORK_NAME_WC || 'binance',
  networkId: process.env.REACT_APP_NETWORK_ID || 56,
  symbolName: process.env.REACT_APP_COIN_SYMBOL || 'BNB',
  coinDecimals: process.env.REACT_APP_COIN_DECIMALS | 18,
  rpc: process.env.REACT_APP_RPC_URL || 'https://bsc-dataseed.binance.org/',
  explorer: process.env.REACT_APP_EXPLORER || 'https://bscscan.com',
  // Token config
  defaultToken: 'BUSD',
  defaultMarketPlaceSymbol: process.env.REACT_APP_DEFAULT_MARKET_SYMBOL ||  'BNB',
  tokens: {
    BUSD: {
      type: 'ERC20',
      options: {
        address: process.env.REACT_APP_BUSD_TOKEN_CONTRACT_ADDRESS || '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        symbol: process.env.REACT_BUSD_APP_TOKEN_SYMBOL || 'BUSD',
        decimals: process.env.REACT_BUSD_APP_TOKEN_DECIMAL || 18,
        image: process.env.REACT_BUSD_APP_TOKEN_IMAGE || 'https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png'
      }
    },
    AMT: {
      type: 'ERC20',
      options: {
        address: process.env.REACT_APP_AMT_TOKEN_CONTRACT_ADDRESS || '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        symbol: process.env.REACT_AMT_APP_TOKEN_SYMBOL || 'AMT',
        decimals: process.env.REACT_AMT_APP_TOKEN_DECIMAL || 18,
        image: process.env.REACT_AMT_APP_TOKEN_IMAGE || '/images/icons/amt.svg'
      }
    },
    AZY: {
      type: 'ERC20',
      options: {
        address: process.env.REACT_APP_AZY_TOKEN_CONTRACT_ADDRESS || '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        symbol: process.env.REACT_AZY_APP_TOKEN_SYMBOL || 'AZY',
        decimals: process.env.REACT_AZY_APP_TOKEN_DECIMAL || 18,
        image: process.env.REACT_AZY_APP_TOKEN_IMAGE || 'https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png'
      }
    }
  }
}
config.explorer = config.explorer.replace(/\/$/g, '')
config.network = {
  chainId: numberToHex(config.networkId),
  chainName: config.networkName,
  nativeCurrency: {
    name: config.networkName,
    symbol: config.symbolName,
    decimals: config.coinDecimals
  },
  rpcUrls: [],
  blockExplorerUrls: [],
  iconUrls: []
}

config.network.rpcUrls.push(config.rpc)
config.network.blockExplorerUrls.push(config.explorer)
config.network.iconUrls.push(process.env.REACT_APP_NETWORK_LOGO || 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png')

export default config
