import React from 'react'
import { Grid } from '../_UI/Grid/Grid'
import styles from './Footer.module.scss'
import cn from 'classnames'

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Grid>
        <section>
          <div className={styles.footer__content}>
            <div className={styles.footer__col}>
              <img src="/images/logo-footer.svg" alt="amazy"/>
              <a href="mailto:support@amazy.io" target="_blank" rel="noreferrer noopener" className={styles.link1}>support@amazy.io</a>
            </div>


            <div className={styles.footer__col}>
              <ul className={styles.social__list}>
                <li><a href="https://t.me/amazyio" target="_blank" rel="noreferrer noopener">
                  <i className="icon_font icon-telegram-1"/>
                  <span>news</span></a>
                </li>
                <li><a href="https://t.me/amazyio_en" target="_blank" rel="noreferrer noopener">
                  <i className="icon_font icon-telegram-1"/>
                  <span>chat</span></a>
                </li>
                <li><a href="https://discord.gg/amazyio" target="_blank" rel="noreferrer noopener"><i className="icon_font icon-discord"/></a></li>
                <li><a href="https://twitter.com/amazyio" target="_blank" rel="noreferrer noopener"><i className="icon_font icon-twitter-1"/></a></li>
                <li><a href="https://www.instagram.com/amazy.io/" target="_blank" rel="noreferrer noopener"><i className="icon_font icon-instagram"/></a></li>
              </ul>
            </div>
          </div>

          <div className={cn(styles.footer__bottom)}>
            <p>© 2022 - all rights reserved 丨 powered by carmellow invest limited</p>
            <p>By using our website, you agree to our <a href="https://amazy.io/privacypolicy" target="_blank" rel="noreferrer noopener">Privacy Policy</a> and our <a href="https://amazy.io/cookiepolicy" target="_blank" rel="noreferrer noopener">Cookies Policy</a>
            </p>
          </div>
        </section>
      </Grid>
    </footer>
  )
}
