import React, { useContext, useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Screens/styles.module.scss'
import config from '../../config'
import Claim from '../../components/_Operations/Claim'
import axios from 'axios'
import { Web3Context } from '../../contexts/Web3Context'

const ClaimFrame = ({ jwt, success, fail, processing }) => {

  const { defaultAccount } = useContext(Web3Context)
  const [amnt, setAmnt] = useState()

  useEffect(() => {
    ;(async () => {
      if (defaultAccount) {
        try {
          const result = await axios.get(`${config.backendEndpoint}get-claim-amount-avail?jwt=${jwt}&from=${defaultAccount}`)
          if (typeof result?.data?.data?.amount !== 'undefined') {
            setAmnt(Math.round((parseFloat(result.data.data.amount) + Number.EPSILON) * 100) / 100)
          } else {
            fail(result.data?.error?.[0]?.msg ?? 'Something went wrong, please try again later')
          }
        } catch (e) {
          fail(e?.response?.data?.error?.[0]?.msg ?? 'Something went wrong, please try again later')
        }
      }
    })()// eslint-disable-next-line
  }, [defaultAccount])

  return (<div>
    <div className={cn(styles.flex_column)}>
      <h4 className={cn(styles.nl)}>{'Claim'}</h4>
      <p className={cn(styles.nl)}>{'You are about to claim AMT tokens'}</p>
      <div className={cn(styles.claim_section)}>
        <img src={config.tokens.AMT.options.image} alt=""/>
        <span>{amnt}<span className={cn(styles.symbol)}>{config.tokens.AMT.options.symbol}</span></span>
      </div>
    </div>
    {amnt > 0 && defaultAccount && <Claim amount={amnt} success={success} fail={fail} processing={processing}/>}
  </div>)
}

export default ClaimFrame
