import React from 'react'
import styles from './SneakersOpenedItem.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { ItemImg } from '../../../_UI/ItemImg/ItemImg'

const SneakersOpenedItem = ({ item, className }) => {
  return (
    <div className={cn(styles.sneakersOpenedItem, className)}>

      <div className={cn(styles.item)}>
        <span className={styles.id}>id# {item.tokenId}</span>
        <h4>{item?.primaryProperties?.Type}</h4>

        <div className={cn('img-rarity', item.primaryProperties.Rarity.toLowerCase())}>
          <ItemImg path={{ type: item.type, ...item.primaryProperties }} alt={'Sneakers'} className={styles.item__img} srcSet={false}/>
        </div>

        {/*--------- Start Characteristic section -----------*/}
        <ul className="characteristics-list">
          <li>
            <span>Performance</span>
            <span>{item?.primaryProperties?.Performance}</span>
          </li>
          <li>
            <span>Fortune</span>
            <span>{item?.primaryProperties?.Fortune}</span>
          </li>
          <li>
            <span>Joy</span>
            <span>{item?.primaryProperties?.Joy}</span>
          </li>
          <li>
            <span>Durability</span>
            <span>{item?.primaryProperties?.Durability}</span>
          </li>
        </ul>
        {/*--------- End Characteristic section -----------*/}
      </div>

      <div className={styles.item__description}>


      </div>

    </div>
  )
}

SneakersOpenedItem.propTypes = {
  item: PropTypes.object,
  className: PropTypes.string
}

export default SneakersOpenedItem