import React from 'react'
import styles from './LayoutMobile.module.scss'
import cn from 'classnames'
import { FooterMobile } from '../FooterMobile/FooterMobile'

export const LayoutMobile = ({ children }) => (
<div className={cn(styles.site_container, `site_container `)}>
  <header  className={styles.header}>
    <img src="/images/logo-txt.svg" alt="Amazy" className={styles.logo}/>
  </header>
  <main  className={cn(styles.site_content, `site_content-mobile `)}>
    {children}
  </main>
  <FooterMobile />
</div>
)