import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from '../../../pages/AppRequests/Screens/styles.module.scss'
import ConnectMetamask from '../../ConnectMetamask'
import { Web3Context } from '../../../contexts/Web3Context'
import { toBN } from 'web3-utils'
import { useApprove } from '../../../hooks/useApprove'
import Catch from '../../Catch'
import NotificationPlane from '../../Screens/NotificationPlane'
import useOverload from '../../../hooks/useOverload'

let timer = undefined

const Claim = ({ amount, fail, success, processing, className }) => {
  const { isConnected, isCorrectNetwork, defaultAccount, redeem, mineRunner721, useSocket, web3 } = useContext(Web3Context)

  const { isApproved, approveButton, checkApprove } = useApprove([{ from: mineRunner721, to: redeem, amount: true, isErc20: false },])

  const { claimEvents } = useSocket(defaultAccount)
  const [waiting, setWaiting] = useState()

  const { data: isNetOverloaded } = useOverload()

  const processSuccess = (text) => {
    clearTimeout(timer)
    processProcessing(false)
    if (typeof success === 'function') success(text)
  }

  const processFail = (message) => {
    processProcessing(false)
    if (typeof fail === 'function') fail(message)
    else Catch('Can`t claim', message)
  }

  const processProcessing = (state) => {
    if (typeof processing === 'function') processing(state)
  }

  useEffect(() => {
    if (!waiting) return
    timer = setTimeout(() => {
      processSuccess(<><h4 className="txt-center">Your repair took a little <br/>longer than expected. </h4><p className="txt-center">Don't worry, your AMT will appear <br/>when
        blockchain <br/>transaction is processed.</p></>)
    }, 60 * 1000)

    return () => clearTimeout(timer)
    // eslint-disable-next-line
  }, [waiting])

  useEffect(() => {
    if (claimEvents) {
      if (claimEvents.failed === false) {
        if (typeof claimEvents.data === 'string') processSuccess(claimEvents.data)
        else processSuccess('Your tokens successfully claimed')
      }
      if (claimEvents.failed === true) {
        processFail(claimEvents.data)
      }
    }// eslint-disable-next-line
  }, [claimEvents])

  const claimTokens = async event => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    const check = await checkApprove()
    if (!check) {
      if (typeof fail === 'function') fail('Error on approve for claim')
      else Catch('Error on approve for claim')
    }

    processProcessing(true)

    try {
      const fee = await redeem.methods.typeInfo('claim').call({ from: defaultAccount })
      const est = await redeem.methods.request('claim', 0, 0, String(amount)).estimateGas({ from: defaultAccount, value: toBN(fee._price) ?? 0 })
      const calculateGP = await web3.eth.getGasPrice()
      const balance = await web3.eth.getBalance(defaultAccount)
      const isEnough = toBN(balance).sub(toBN(fee._price)).sub(toBN(est)).gtn(0)
      if (!isEnough) return processFail('Not enough BNB on balance')
      redeem.methods.request('claim', 0, 0, String(amount)).send({ from: defaultAccount, value: toBN(fee._price) ?? 0, gasPrice: calculateGP }).once('receipt', () => {
        setWaiting(true)
      }).on('error', ({ message }) => {
        processFail(message)
      })
    } catch ({ message }) {
      processFail(message)
    }
  }

  return (
    <div className="claim-comp-container">
      {(!isConnected || !isCorrectNetwork) && <ConnectMetamask/>}

      {isNetOverloaded && <NotificationPlane/>}

      {isConnected && isCorrectNetwork &&
        <div className={cn(styles.btn_container, className)}>
          {!isApproved && approveButton('Approve claim')}
          {isApproved &&
            <button className={cn(styles.btn, 'btn btn-gradient btn-gradient1',(amount <= 0) && 'btn-disabled')}
                    disabled={amount <= 0}
                    onClick={(event) => claimTokens(event)}><span>Claim {Math.floor(amount * 1000) / 1000} AMT</span></button>
          }
        </div>
      }
    </div>
  )
}

Claim.propTypes = {
  amount: PropTypes.number,
  fail: PropTypes.func,
  success: PropTypes.func,
  processing: PropTypes.func,
}

export default Claim
