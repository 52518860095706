import React, { useContext, useEffect, useState } from 'react'
import { Web3Context } from '../../contexts/Web3Context'
import OpenBox from '../../components/_Operations/OpenBox/OpenBox'
import InitScreen from './Screens/InitScreen'
import axios from 'axios'
import configUI from '../../config/configUI'
import config from '../../config'

const Redeem = ({ success, fail, processing, tokenId }) => {
  const { defaultAccount, useSocket } = useContext(Web3Context)
  useSocket(defaultAccount)

  const [item, setItem] = useState()

  useEffect(() => {
    (async () => {
      try {
        const tItem = await axios.get(config.backendEndpoint + 'item/' + tokenId)
        if (tItem.data) {
          setItem(tItem.data)
          if (tItem.data.type.toLowerCase() !== configUI.box) {
            if (typeof fail === 'function') fail('You can only open boxes!')
          }
          if (defaultAccount && tItem.data.owner !== defaultAccount) {
            if (typeof fail === 'function') fail('You are not a token owner')
          }
        } else {
          if (typeof fail === 'function') fail('Box not found')
        }
      } catch ({ message }) {
        console.error(message)
        if (typeof fail === 'function') fail(message)
      }
    })()
    // eslint-disable-next-line
  }, [tokenId])

  return (item &&
    <>
      <InitScreen id={item.tokenId} description={'Box is about to open and you\'ll get your sneakers'} title={'Redeem'} itemTitle={[item?.primaryProperties?.Rarity, 'Box'].join(' ')}/>
      <OpenBox token={item} success={success} fail={fail} processing={processing}/>
    </>) || <></>
}

export default Redeem
