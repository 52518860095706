import React from 'react'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'
import cn from "classnames";

export const NotificationPlane = ({icon, title, text, className}) => {


    return (
        <div className={cn(styles.flex_column, styles.notification_plane, className)}>
            { icon && <img src={icon} alt=''/> }
            { title && <h5 className={cn(styles.nl)} >{title}</h5> }
            { text && <p className={cn(styles.nl)} >{text}</p> }
        </div>
    )
}

NotificationPlane.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
};

NotificationPlane.defaultProps = {
    text: 'Now you need to go back to the AMAZY app on your own and refresh the page\n',
};


