import React from 'react';
import styles from './CopyButton.module.scss';
import {Tooltip} from 'antd';

const CopyButton = ({text}) => {

  const copyToClipboard = (text, event) => {
    event.stopPropagation();
    return navigator.clipboard.writeText(text);
  };

  return (
      <Tooltip title="copied!" trigger="click">
        <button className={styles.copy} onClick={(event) => copyToClipboard(text, event)}>
          <img src="/images/icons/copy-address.svg" alt="copy address"/>
        </button>
      </Tooltip>
  );
};

export default CopyButton;