import React, { useEffect, useState } from 'react'
import styles from './Buy.module.scss'
import cn from 'classnames'
import Catch from '../../Catch'
import { useContext } from 'react'
import { Web3Context } from '../../../contexts/Web3Context'
import config from '../../../config'
import erc20 from '../../../config/Erc20'
import ConnectMetamask from '../../ConnectMetamask'
import AnimatedLoading from '../../_UI/AnimatedLoading/AnimatedLoading'
import { useParams } from 'react-router-dom'
import { fromWei, toBN } from 'web3-utils'
import { useStateIfMounted } from 'use-state-if-mounted'
import useOverload from '../../../hooks/useOverload'
import NotificationPlane from '../../Screens/NotificationPlane'

const Buy = ({ callback, customBuyText }) => {

  const { getSymbol, checkSale, mineRunner721, defaultAccount, web3, isConnected, isCorrectNetwork } = useContext(Web3Context)

  const [buyProcess, setBuyProcess] = useStateIfMounted(true)
  const [readyProcess, setReadyProcess] = useState(false)
  const [approveInfo, setApproveInfo] = useStateIfMounted({ approved: false, alreadyApproved: 0, bought: 0, total: '0', })
  const [saleInfo, setSaleInfo] = useStateIfMounted({})
  const [erc, setErc] = useStateIfMounted({})
  const [buyText, setBuyText] = useState('Buy')

  const { data: isNetOverloaded } = useOverload()

  const { sid } = useParams()
  let seasonId = config.seasonId
  if (config.isDebug && sid) {
    seasonId = sid
  }

  useEffect(() => {
    (async () => {
      if (mineRunner721.methods && defaultAccount) {
        try {
          const saleInfo = await checkSale(seasonId)
          setSaleInfo(saleInfo)
          if (saleInfo._tokens) {
            const erc = new web3.eth.Contract(erc20.abi, saleInfo._tokenContract)
            setErc(erc)
            const bought = await mineRunner721.methods.tokensBoughtByAddress(seasonId, defaultAccount).call({ from: defaultAccount })
            const total = toBN(saleInfo._seasonPrice).muln(parseInt(saleInfo._tokensPerBuyer) - parseInt(bought)).toString()
            let alreadyApproved = await erc.methods.allowance(defaultAccount, mineRunner721.options.address).call({ from: defaultAccount })
            setApproveInfo({ approved: toBN(alreadyApproved).gte(toBN(total)), alreadyApproved, bought, total })
          }
          if (customBuyText) {
            const text = <span>Buy for <span><img src="/images/icons/busd.svg" className={cn('btn_coin_icon')} alt=""/></span> {fromWei(saleInfo._seasonPrice, 'ether')} {getSymbol(saleInfo._tokenContract)}</span>
            setBuyText(text)
          }
        } catch (e) {
          console.log(e.message)
        }
        setBuyProcess(false)
      }
    })()// eslint-disable-next-line
  }, [defaultAccount, web3, mineRunner721])

  const approveSubmit = async () => {
    if (!approveInfo.approved) { //if buy for erc20 and not approved yet
      setBuyProcess(true)
      try {
        const calculateGP = await web3.eth.getGasPrice()
        await erc.methods.increaseAllowance(mineRunner721.options.address, approveInfo.total).send({ from: defaultAccount, gasPrice: calculateGP }).on('receipt', () => {
          approveInfo.approved = true
          setApproveInfo(approveInfo)
          setBuyProcess(false)
        }).on('error', () => {
          setBuyProcess(false)
        })
      } catch (e) {
        return Catch('Can`t approve buy', e.message)
      }
    }
  }

  const buySubmit = async () => {
    try {
      if (!isConnected || !isCorrectNetwork) return Catch('Wrong network or not connected')
      setBuyProcess(true)
      setReadyProcess(false)

      //check is sale active
      if (!saleInfo.isActive) {
        setBuyProcess(false)
        return Catch('No one active sale found!')
      }

      //check is sale active
      if (saleInfo.seasonAmount < 1) {
        setBuyProcess(false)
        return Catch('All tokens sold!')
      }

      if (saleInfo._whitelist) { // if sale by white list check if in list
        const isInWhite = await mineRunner721.methods.isWhitelisted(defaultAccount, seasonId).call({ from: defaultAccount })
        if (!isInWhite) {
          setBuyProcess(false)
          return Catch('You are not allowed to buy')
        }
      }

      // check sale limit nft per address
      if (parseInt(approveInfo.bought) >= parseInt(saleInfo._tokensPerBuyer)) {
        setBuyProcess(false)
        return Catch('Buy limit exceeded for the one user')
      }

      const balance = await erc.methods.balanceOf(defaultAccount).call({ from: defaultAccount })
      if (toBN(balance).lt(toBN(saleInfo._seasonPrice))) {
        const sym = await getSymbol(saleInfo._tokenContract)
        setBuyProcess(false)
        return Catch('Not enough ' + sym + ' tokens to buy!')
      }

      if (approveInfo.approved) {
        setReadyProcess(true)
        setTimeout(() => {
          setBuyProcess(false)
          setReadyProcess(false)
        }, 2000)

        const calculateGP = await web3.eth.getGasPrice()
        const opts = { from: defaultAccount, gasPrice: calculateGP }

        if (!saleInfo._tokens) {
          opts.value = saleInfo._seasonPrice
        }

        await mineRunner721.methods.saleMint(defaultAccount, seasonId).send(opts).on('receipt', () => {
          callback('You can see purchased \n items in inventory')
          return true
        })
      }
    } catch ({ message }) {
      setBuyProcess(false)
      return Catch('Can`t buy item', message)
    }
  }

  return (
    <div className="buy-comp-container">
      {(!isConnected || !isCorrectNetwork) && <ConnectMetamask/>}

      {isNetOverloaded && <NotificationPlane/>}

      {isConnected && isCorrectNetwork &&
        <button className={cn(styles.btn, 'btn btn-gradient btn-gradient1')} onClick={approveInfo.approved ? buySubmit : approveSubmit} disabled={buyProcess}>
          {!readyProcess && (
            <>
              {buyProcess && <span><AnimatedLoading className={cn(styles.loading)}/>Processing</span>}
              {!buyProcess && !approveInfo.approved && <span>Approve</span>}
              {!buyProcess && approveInfo.approved && <span>{buyText}</span>}
            </>
          )}
          {readyProcess && <span><img src="/images/icons/done.svg" alt=""/> Ready</span>}
        </button>
      }

    </div>
  )
}

export default Buy