import React from 'react'
import Header from '../Header/Header'
import { Footer } from '../Footer/Footer'

export const Layout = ({ children }) => (
<div className={`site_container `}>
  <Header />
  <main className="site_content">
    {children}
  </main>
  <Footer />
</div>
)