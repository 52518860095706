import React from 'react'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { ItemImg } from '../../../components/_UI/ItemImg/ItemImg'

export const ItemPlane = ({ title, id, img, className }) => {

  return (
    <div className={cn(styles.flex_column, styles.item_plane, className)}>
      {id && <span className={cn(styles.id)}>{id}</span>}
      <h5 className={cn(styles.nl)}>{title}</h5>
      {img && <ItemImg path={img.toLowerCase()} srcSet={false}/>}
    </div>
  )
}

ItemPlane.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
ItemPlane.defaultProps = {
  title: 'Common Box',
  img: '/images/boxes/common.png',
}


