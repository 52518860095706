import React, { useContext, useEffect } from 'react'
import { ItemPlane } from './Screens/ItemPlane'
import useItem from '../../hooks/useItem'
import cn from 'classnames'
import PairingProcess from '../../components/_Operations/Mint/PairingProcess'
import styles from './Screens/styles.module.scss'
import { Web3Context } from '../../contexts/Web3Context'
import configUI from '../../config/configUI'
import moment from 'moment'
import axios from 'axios'
import config from '../../config'

const PairingFrame = ({ fatherId, motherId, amountAMT, amountAZY, fail, success, processing }) => {
  const { data: father } = useItem(fatherId)
  const { data: mother } = useItem(motherId)

  const { defaultAccount, isConnected } = useContext(Web3Context)

  useEffect(() => {
    if (defaultAccount && isConnected && father && mother) {
      if (father && father.owner !== defaultAccount) {
        if (typeof fail === 'function') fail('You should be owner to process minting')
      }
      if (father.type === configUI.box) {
        if (typeof fail === 'function') fail('You can`t mint box')
      }
      if (father?.pairingCoolDown > moment().unix()) {
        if (typeof fail === 'function') fail('You can mint sneakers only once every 72 hours!')
      }
      if (mother && mother.owner !== defaultAccount) {
        if (typeof fail === 'function') fail('You should be owner to process minting')
      }
      if (mother.type === configUI.box) {
        if (typeof fail === 'function') fail('You can`t mint box')
      }
      if (mother?.pairingCoolDown > moment().unix()) {
        if (typeof fail === 'function') fail('You can mint sneakers only once every 72 hours!')
      }
      ;(async () => {
        try {
          const tItems = await axios.get(config.backendEndpoint + 'game/pairing-cooldown?from=' + defaultAccount)
          if (tItems.data.ok === true) {
            if (tItems.data.data > 0) {
              if (typeof fail === 'function') fail('You can mint sneakers only once every 72 hours!')
            }
          }
        } catch (e) {
          fail(e?.response?.data?.error?.[0]?.msg ?? 'Something went wrong, please try again later')
        }
      })()
    }// eslint-disable-next-line
  }, [isConnected, father, mother, defaultAccount])

  return <>
    {father && mother && amountAMT && amountAZY &&
      <div className="redeem-comp-container">
        <div className={cn(styles.flex_column)}>
          <h4 className={cn(styles.nl)}>Minting</h4>
          <p className={cn(styles.nl)}>Sneakers is about to minting</p>
          <ItemPlane title={father.name} id={fatherId} img={`/images/sneakers/${father?.primaryProperties?.Rarity}/${father?.primaryProperties?.Type}/${father?.primaryProperties?.Skin}.png`}/>
          <ItemPlane title={mother.name} id={motherId} img={`/images/sneakers/${mother?.primaryProperties?.Rarity}/${mother?.primaryProperties?.Type}/${mother?.primaryProperties?.Skin}.png`}/>
        </div>
        <PairingProcess father={father} mother={mother} amountAMT={amountAMT} amountAZY={amountAZY} success={success} fail={fail} processing={processing}/>
      </div>
    }
  </>
}

export default PairingFrame
