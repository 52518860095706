import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import styles from './Header.module.scss'
import cn from 'classnames'
import { Grid } from '../_UI/Grid/Grid'
import { FirstVisitPopup } from '../_UI/FirstVisitPopup/FirstVisitPopup'
import Modal from '../_UI/Modal'
import User from './User'
import { Web3Context } from '../../contexts/Web3Context'
import ConnectMetamask from '../ConnectMetamask'
import axios from 'axios'
import config from '../../config'
import Catch from '../Catch'
import ToolTipCustom from '../_UI/ToolTipCustom'

const Header = () => {

  const { isConnected, isCorrectNetwork, defaultAccount } = useContext(Web3Context)
  const history = useHistory()
  const location = useLocation()
  const [isAddressBound, setIsAddressBound] = useState(true)
  const [isActivated, setIsActivated] = useState(true)
  const [visibleFirstVisitModal, setVisibleFirstVisitModal] = useState(false)

  const skippedSignup = +localStorage.getItem('skippedSignup')

  const hideFirstVisitModal = async () => {
    setVisibleFirstVisitModal(false)
    localStorage.setItem('startupPopup', '1')
  }

  useEffect(() => {
    const start = localStorage.getItem('startupPopup')
    const skip = '/game/delete-account' === location.pathname
    setVisibleFirstVisitModal(start !== '1' && !skip)
  }, [location])

  useEffect(() => {
    ;(async () => {
      if (!isConnected || !defaultAccount) return
      if (!['/register', '/register/signup', '/login'].includes(location.pathname) && defaultAccount) {
        try {
          const result = await axios.get(`${config.backendEndpoint}check-is-address-bound?from=${defaultAccount}`)
          if (!result?.data?.data?.exists) {
            localStorage.removeItem('accessToken')
            if (!skippedSignup) history.push('/register')
            setIsAddressBound(false)
          } else {
            setIsAddressBound(true)
          }
          setIsActivated(result?.data?.data?.activated)
        } catch ({ message }) {
          Catch(message)
        }
      }
    })() // eslint-disable-next-line
  }, [isConnected, history, location, defaultAccount, skippedSignup, performance.navigation.type])

  return (
    <header className={styles.header}>
      <Grid>
        <div className={styles.header__inner}>
          <Link to="/">
            <div className={styles.logo_container}>
              <img src="/images/logo.svg" alt="amazy"/>
            </div>
          </Link>

          <ul className={cn(styles.nav, 'site-navigation')}>
            {/*<li>
              <NavLink to="/" exact activeClassName={styles.active}>home</NavLink>
            </li>
            <li>
              <NavLink to="/boxes" activeClassName={styles.active}>box sale</NavLink>
              </li>*/}
            <li>
              <NavLink to="/marketplace" exact activeClassName={styles.active}>marketplace</NavLink>
            </li>

            {/*<li className={styles.menu__last}>
              <a href="https://amazy.io/#gameplay" target="_blank" rel="noreferrer noopener">
                how it works?
                <span className={styles.external_link}>
                    <img src="/images/icons/arrow-external.svg" alt=""/>
                  </span>
              </a>
            </li>*/}
          </ul>


          <div className={styles.help_section}>
            <p>Any questions? <br/>Contact Us</p>
            <ul className={styles.social__list}>
              <li>
                <a href="https://t.me/AMAZY_CARE_SERVICE_BOT" target="_blank" rel="noreferrer noopener">
                  <img src="/images/icons/header/telegram.svg" alt="telegram"/>
                  <ToolTipCustom showIcon={false} className={styles.tooltip}>Telegram</ToolTipCustom>
                </a>
              </li>
              <li>
                <a href="https://wa.me/message/JU3QU5K6JDH5M1" target="_blank" rel="noreferrer noopener">
                  <img src="/images/icons/header/whatsapp.svg" alt="whatsapp"/>
                  <ToolTipCustom showIcon={false} className={styles.tooltip}>Whatsapp</ToolTipCustom>
                </a>
              </li>
              <li>
                <button onClick={() => window.usedeskMessenger.open('chat')}>
                  <img src="/images/icons/header/chat.svg" alt="chat"/>
                  <ToolTipCustom showIcon={false} className={styles.tooltip}>Chat</ToolTipCustom>
                </button>
              </li>
            </ul>
          </div>

          <div className={styles.header__right}>



            {!window.ethereum && <a href="https://metamask.io/download/" target="_blank" rel="noreferrer noopener" className={styles.download_mm}>
              Install Metamask
              <span>
                    <img src="/images/icons/download.svg" alt=""/>
                  </span>
            </a>}

            {(!isConnected || !isCorrectNetwork) && <ConnectMetamask longTxt={true}/>}
            {isConnected && isCorrectNetwork && <User isActivated={isActivated} isAddressBound={isAddressBound}/>}
          </div>
        </div>
      </Grid>


      {visibleFirstVisitModal &&
        <Modal
          visible={visibleFirstVisitModal}
          onClose={hideFirstVisitModal}
          onClickOutside={() => {}}
          size={'auto'}
        >
          <FirstVisitPopup onClose={hideFirstVisitModal}/>
        </Modal>
      }

    </header>
  )
}

export default Header