import React from 'react'

const AnimatedLoading = ({ className }) => {
    return (
        <div className={`sk-chase ${className ? className : ''}`}>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
        </div>
    )
}

export default AnimatedLoading
