import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import config from '../config'
import Web3Modal from 'web3modal'
import Web3 from 'web3'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { toBN } from 'web3-utils'
import contract from '../config/MineRunner721'
import marketPlace from '../config/Marketplace'
import redeemContract from '../config/Redeem'
import depositContract from '../config/Deposit'
import lockContract from '../config/Lock'
import erc20 from '../config/Erc20'
import moment from 'moment'
import Catch from '../components/Catch'
import io from 'socket.io-client'
import PropTypes from 'prop-types'
import { queryClient } from '../config/queryClient'
import axios from 'axios'
import { AuthContext } from './AuthContext'

export const Web3Context = createContext({})

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: { [config.networkId]: config.rpc },
      network: config.networkNameWC
    }
  }
}

const web3Modal = new Web3Modal({
  cacheProvider: true,
  theme: 'dark',
  providerOptions // required
})

const Web3ContextWrapper = ({ children }) => {
  const [web3Provider, setWeb3Provider] = useState(false)
  const [web3, setWeb3] = useState(false)
  const [networkId, setNetworkId] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [defaultAccount, setDefaultAccount] = useState(false)
  const [defaultAccountBalance, setDefaultAccountBalance] = useState('0')
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false)
  const [mineRunner721, setMineRunner721] = useState({})
  const [market, setMarket] = useState({})
  const [redeem, setRedeem] = useState({})
  const [deposit, setDeposit] = useState({})
  const [lock, setLock] = useState({})
  const [saleInfo, setSaleInfo] = useState({ isActive: false })
  const [symbol, setSymbol] = useState('BUSD')
  const [updates, setUpdates] = useState({})
  const [upgrade, setUpgrade] = useState({})
  const [redeemEvents, setRedeemEvents] = useState({})
  const [chipBoxEvents, setChipBoxEvents] = useState({})
  const [pairingEvents, setPairingEvents] = useState({})
  const [upgradeChipEvents, setUpgradeChipEvents] = useState({})
  const [syncEvents, setSyncEvents] = useState({})
  const [claimEvents, setClaimEvents] = useState({})

  const { setAuth } = useContext(AuthContext)

  const socket = useRef({})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => connectHandler(), [])

  const connectHandler = async (force = false) => {
    try {
      let provider = false
      let providerNetwork = -1
      let account = false
      let balance = '0'

      if (web3Modal.cachedProvider) provider = await web3Modal.connect()
      if (!provider && force) provider = await web3Modal.connect()

      if (provider) {
        provider.on('chainChanged', () => {
          connectHandler(true)
        })
        provider.on('accountsChanged', () => {
          // localStorage.removeItem('skippedSignup')
          localStorage.removeItem('accessToken')
          connectHandler()
        })

        provider.on('disconnect', () => disconnect())

        const web = new Web3(provider)
        setWeb3(web)

        providerNetwork = await web.eth.net.getId()
        providerNetwork = parseInt(providerNetwork)

        const accounts = await web.eth.getAccounts()
        account = accounts[0] ?? false
        balance = account ? await web.eth.getBalance(accounts[0]) : '0'
        balance = await rnd(balance)

        if (providerNetwork === parseInt(config.networkId)) {
          const ax721 = new web.eth.Contract(contract.abi, contract.networks[providerNetwork].address)
          setMineRunner721(ax721)
          const mp = new web.eth.Contract(marketPlace.abi, marketPlace.networks[providerNetwork].address)
          setMarket(mp)
          const rd = new web.eth.Contract(redeemContract.abi, redeemContract.networks[providerNetwork].address)
          setRedeem(rd)
          const lk = new web.eth.Contract(lockContract.abi, lockContract.networks[providerNetwork].address)
          setLock(lk)
          const depo = new web.eth.Contract(depositContract.abi, depositContract.networks[providerNetwork].address)
          setDeposit(depo)
        }
      }

      setWeb3Provider(provider)
      setDefaultAccount(account)
      setDefaultAccountBalance(balance)
      setNetworkId(providerNetwork)
      setIsConnected(provider && account)
      setIsCorrectNetwork(provider && account && providerNetwork === parseInt(config.networkId))

      return true
    } catch (error) {
      if (error != null) {
        return Catch(String(error))
      }
      console.error({ web3ModalError: error })
      return false
    }
  }

  /* const toUsd = (amount, rate) => {
      const ether = fromWei(`${amount}`, 'ether');
      return Math.floor(ether * rate);
    }; */

  const getErc20Contract = (address) => {
    try {
      if (!web3.eth) return {}
      return new web3.eth.Contract(erc20.abi, address)
    } catch ({ message }) {
      console.error(message)
      return {}
    }
  }

  const getSymbol = address => {
    if (!web3 || !web3.eth) return symbol
    const sym = Object.values(config.tokens).filter(i => i?.options?.address === address)
    const res = sym?.[0]?.options?.symbol ? sym[0].options.symbol : config.tokens[config.defaultToken].options.symbol
    setSymbol(res)

    return res
  }

  const rnd = async (amount, token = '0x0000000000000000000000000000000000000000') => {
    if (amount == null) return 0
    let decimals = config.coinDecimals
    if (token !== '0x0000000000000000000000000000000000000000' && web3.eth != null) {
      try {
        const isContract = await web3.eth.getCode(token)
        if (isContract === '0x') return 0
        const contract = new web3.eth.Contract(erc20.abi, token)
        decimals = await contract.methods.decimals().call({ from: defaultAccount })
      } catch ({ message }) {
        console.error(message)
      }
    }

    const decimalsSTR = toBN(10).pow(toBN(decimals)).toString()
    const ether = +amount / +decimalsSTR

    return Math.floor(ether * 1000) / 1000
  }

  const connectWeb3 = async () => {
    web3Modal.clearCachedProvider()

    return await connectHandler(true)
  }

  const disconnect = useCallback(async () => {
    setWeb3Provider(false)
    setWeb3(false)
    setNetworkId(false)
    setIsConnected(false)
    setDefaultAccount(false)
    web3Modal.clearCachedProvider()
    try {
      const jwt = localStorage.getItem('accessToken')
      if (jwt)
        await axios.post(config.backendEndpoint + 'profile/signout', { web: 1 }, { headers: { Authorization: `Bearer ${jwt}` } })
    } catch (e) {
    }
    localStorage.removeItem('accessToken')
    localStorage.removeItem('skippedSignup')
    setAuth(false)
  }, [setAuth])

  const shortenAddress = (address, chars = 4) => {
    return `${address.substring(0, chars + 2)}...${address.substring(42 - chars)}`
  }

  const checkSale = async (sid) => {
    sid = sid || config.seasonId
    const sale = await mineRunner721.methods.seasonInfo(sid).call({ from: defaultAccount })
    sale.isActive = sale._seasonStart * 1000 < moment() && sale._seasonStop * 1000 > moment()
    const saleOpt = await mineRunner721.methods.seasonOptions(sid).call({ from: defaultAccount })
    setSaleInfo(Object.assign(sale, saleOpt))

    return Object.assign(sale, saleOpt)
  }

  const signMgs = async (data) => {
    if (typeof data === 'object') {
      data = JSON.stringify(data)
    }

    return  await web3Provider.request({ method: 'personal_sign', params: [data, defaultAccount] })
  }

  const handleSocket = (roomId) => {
    if (!socket.current?.[roomId]) {
      socket.current[roomId] = io(config.socketEndpoint, {
        query: { roomId },
        transports: ['websocket']
      })

      socket.current[roomId].on('updates', data => {
        setUpdates(data)
      })

      socket.current[roomId].on('upgrade', data => {
        setUpgrade(data)
      })

      socket.current[roomId].on('redeem', data => {
        setRedeemEvents(data)
      })

      socket.current[roomId].on('openBox', data => {
        setChipBoxEvents(data)
      })

      socket.current[roomId].on('sync', data => {
        setSyncEvents(data)
      })

      socket.current[roomId].on('pairing', data => {
        setPairingEvents(data)
      })

      socket.current[roomId].on('upgradeChip', data => {
        setUpgradeChipEvents(data)
      })

      socket.current[roomId].on('claim', data => {
        setClaimEvents(data)
      })

      socket.current[roomId].on('disconnect', () => {
        handleSocket(roomId)
      })

      socket.current[roomId].onAny(() => {
        console.log('Invalidating queries')
        queryClient.invalidateQueries()
      })
    }
  }

  const useSocket = (roomId) => {
    useEffect(() => {
      if (!roomId) return
      if (!socket.current?.[roomId]) {
        handleSocket(roomId)
      }
    }, [roomId])

    const sendMessage = (data) => {
      socket.current[roomId].emit('request', { defaultAccount, data })
    }

    return { updates, upgrade, redeemEvents, chipBoxEvents, syncEvents, pairingEvents, upgradeChipEvents, claimEvents, sendMessage }
  }

  return (
    <Web3Context.Provider
      value={{
        web3,
        isConnected,
        isCorrectNetwork,
        web3Provider,
        networkId,
        defaultAccount,
        defaultAccountBalance,
        connectWeb3,
        disconnect,
        checkSale,
        mineRunner721,
        market,
        lock,
        redeem,
        deposit,
        saleInfo,
        shortenAddress,
        rnd,
        signMgs,
        symbol,
        getSymbol,
        getErc20Contract,
        useSocket
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}

Web3ContextWrapper.propTypes = {
  children: PropTypes.any
}

export default Web3ContextWrapper
