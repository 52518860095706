import React, { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
//import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import styles from "./Modal.module.scss";
import Icon from "../../Icon";

const Modal = ({
  outerClassName,
  containerClassName,
  visible,
  onClose,
  onClickOutside,
  size,
  children,
}) => {
  const escFunction = useCallback(
    (e) => {
      // if escape key pressed
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const scrollRef = useRef(Math.random().toFixed(0));

  if(!onClickOutside) {
    onClickOutside =  onClose;
  }

  //todo: не работает как надо со scrollRef.current и валится на йосе с scrollRef
  // useEffect(() => {
  //   visible ? disableBodyScroll(scrollRef) : enableBodyScroll(scrollRef);
  // }, [visible]);

  return createPortal(
    visible && (
      <div className={cn(styles.modal,
          size==='small' && styles.modal__small,
          size==='auto' && styles.modal__auto,
          size==='big' && styles.modal__big)} ref={scrollRef}>
        <div className={cn(styles.outer, outerClassName)}>
          <OutsideClickHandler onOutsideClick={onClickOutside}>
            <div className={cn(styles.container, containerClassName)}>
              {children}
              <button className={styles.close} onClick={onClose}>
                {/*<i className="icon_font icon-cancel "></i>*/}
                <Icon name="close" size={12} />
              </button>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  );
};

export default Modal;
