import { createContext, useState } from 'react'

export const AuthContext = createContext({})

const AuthContextWrapper = ({ children }) => {
  const [auth, setAuth] = useState(Boolean(localStorage.getItem('accessToken')))
  const jwt = (newToken) => {
    if (newToken) localStorage.setItem('accessToken', newToken)
    return localStorage.getItem('accessToken')
  }

  const checkAuth = msg => {
    if (msg === 'jwt invalid') {
      setAuth(false)
      localStorage.removeItem('accessToken')
    }
  }

  return <AuthContext.Provider value={{ auth, setAuth, jwt, checkAuth }}>{children}</AuthContext.Provider>
}

export default AuthContextWrapper
