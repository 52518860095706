import React from 'react'
import PropTypes from 'prop-types'
import ucfirst from 'ucfirst'
import * as pathLib from 'path'
import configUI from '../../../config/configUI'

export const ItemImg = ({ path, width, alt, className, srcSet }) => {
  const prefix = process.env.REACT_APP_REMOTE_IMAGES ?? ''

  const concatPath = opts => {
    const name = opts?.Skin?.toLowerCase() ?? '../sneakers'

    switch (opts.type) {
      case configUI.box:
        return prefix + '/' + ['images', 'boxes', opts?.Rarity?.toLowerCase() + '.png'].join('/')

      case configUI.chipBox:
        return '/' + ['images', 'chipboxes', opts?.Rarity?.toLowerCase() + '.png'].join('/')

      case configUI.sneakers:
        return prefix + '/' + ['images', 'sneakers', opts?.Rarity?.toLowerCase(), opts?.Type?.toLowerCase(), name + '.png'].join('/')

      case configUI.chip:
        return '/' + ['images', 'chips', opts.Type.toLowerCase(), `${opts.Type.toLowerCase()}-level-${configUI.chipLevel[opts.Rarity]}.png`].join('/')

      default:
        return '/' + pathLib.join('/')
    }
  }

  if (typeof path === 'object') {
    path = concatPath(path)
  } else {
    path = prefix + '/' + path.replace(/^\//, '')
  }

  alt = ucfirst(alt)
  path = path.toLowerCase()

  const x2 = path.replace(/(\.[\w]+)$/, '@2x$1')
  const srcSetParams = srcSet ? `${path} 1x, ${x2} 2x` : ''

  return (
    <img
      srcSet={srcSetParams}
      src={path}
      width={width}
      alt={alt}
      className={className}
    />
  )
}

ItemImg.propTypes = {
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  alt: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  srcSet: PropTypes.bool,
}

ItemImg.defaultProps = {
  width: 'auto',
  alt: '',
  srcSet: true,
}

/*Sample of use*/
/*
<ItemImg path={`/images/boxes/${rarity}.png`} alt={'Box'} width={460}/>
<ItemImg path={{type: token.type, size: 'small', name: 'default-disabled', ...token.primaryProperties}} width={230} alt={token.type}/>
*/
