import React from 'react'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { createPortal } from 'react-dom'

export const MessageType = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  warningOrange: 'warningOrange',
  warningRed: 'warningRed',
}

const MessageScreen = ({ children, title, description, type = MessageType.success, className, btnText, onClose, isPortal = false }) => {
  let image

  switch (type) {
    case MessageType.success:
      image = 'success-primary'
      break
    case MessageType.error:
      image = 'fail'
      break
    case MessageType.warning:
      image = 'warning'
      break
    case MessageType.warningOrange:
      image = 'warning-orange'
      break
    case MessageType.warningRed:
      image = 'warning-red'
      break
    default:
      return 'success-primary'
  }

  const messageContent = () => {
    return (
      <div className={cn(styles.message__container, isPortal && styles.isPortal)}>
        <div className={cn(styles.message__content, className)}>
          <img src={`/images/icons/${image}.svg`} className={cn(styles.icon_img)} alt=""/>
          <h4 className={cn(styles.nl)}>{title}</h4>
          {description && <p className={cn(styles.nl)}>{description}</p>}

          {children}

          <button className={cn(styles.btn, 'btn btn-gradient btn-gradient1')} onClick={() => onClose()}>
            <span>{btnText}</span>
          </button>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (isPortal) {
      return createPortal(
        messageContent(), document.body
      )
    } else {
      return messageContent()
    }
  }

  return renderContent()
}

MessageScreen.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  btnText: PropTypes.string,
  onClose: PropTypes.func
}
MessageScreen.defaultProps = {
  title: '',
  description: '',
  className: '',
  btnText: 'Go to inventory'
}

export default MessageScreen
